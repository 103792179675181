import React, { useState, useEffect, useContext, useRef } from "react";
import {
  AntDesign,
  Ionicons,
  MaterialCommunityIcons,
} from "@expo/vector-icons";
import { Link, NavLink, useNavigate, useParams } from "react-router-dom";
import BrandCreationComponent from "../../components/BrandCreationComponent/BrandCreationComponent";
import Footer from "../../components/Footer/Footer";

export default function CreateBrand1(props: any) {
  const navigate = useNavigate();

  useEffect(() => {}, []);

  return (
    <div className="page-main">
      {/* header */}
      <div>
        {/* navbar-custom */}
        <nav className="navbar navbar-expand-lg navbar fixed-top navbar-light navbar-custom-new">
          <div className="row w-100 align-items-center">
            <div className="col-md-10 col-10">
              <div className="d-flex align-items-center py-2 px-1">
                <img
                  src={require("../../assets/img/shopping-cart.png")}
                  className="logo-img"
                />
                <h6 className="mb-0 ps-1">SELLERS</h6>
              </div>
            </div>
            <div className="col-md-2 col-2 pe-0 ">
              <div className="sidebar-options dropdown text-right">
                <Link to="/" className="p-0 mt-0">
                  <div
                    className="pe-3 cursor"
                    data-bs-toggle="tooltip"
                    title="Home"
                  >
                    <AntDesign name="home" size={20} color="#777" />
                  </div>
                </Link>

                <div className="d-md-block d-sm-none">
                  <div
                    className="pe-3 cursor"
                    data-bs-toggle="tooltip"
                    title="Notifications"
                  >
                    <Ionicons
                      name="ios-notifications-outline"
                      size={22}
                      color="#777"
                    />
                  </div>
                </div>

                <div className="d-md-block d-sm-none">
                  <Link to="" className="p-0 mt-0">
                    <div
                      className="pe-3 cursor"
                      data-bs-toggle="tooltip"
                      title="Logout"
                    >
                      <AntDesign name="logout" size={17} color="#777" />
                    </div>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </nav>
      </div>
      <BrandCreationComponent
        call_back={(data: any) => {
          if (data.from == "save") {
            console.log("on call_back save :", data);
            navigate("/brand/" + data.data.brand_id + "/quick_create");
          }
        }}
      />
      {/* footer */}
      <Footer
        menu_click={(data: any) => {
          // on_menu_click(data);
        }}
        from="main"
      />
    </div>
  );
}
