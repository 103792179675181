import React, { useState, useEffect, useContext, useRef } from "react";
import { AppConfig } from "../../config";
import LocationPicker from "../../components/LocationPicker/LocationPicker";
import { api } from "../../utils/Api";
import DataContext from "../../utils/Context/DataContext";
import toast from "react-hot-toast";

const initial_value = {
  brand_name: "",
  outlet_name: "",
  outlet_map_data: {},
  channel_data: {
    id: "2",
    name: "1SuperApp Market Place",
  },
  brand_image: [],
  banner_image: [],
  door_no: "",
  address: "",
  mobile: "",
  city: "",
  country: {
    id: 99,
    iso: "IN",
    name: "INDIA",
    nicename: "India",
    iso3: "IND",
    numcode: 356,
    phonecode: 91,
    value: 99,
    label: "India",
  },
  state: {
    id: 31,
    name: "TamilNadu",
  },
  pincode: "",
  approx_preparation_time: "20",
  cod: 0,
};

export default function BrandCreationComponent(props: any) {
  const context: any = useContext(DataContext);
  const BrandImageInputRef = React.createRef();
  const BannerImageInputRef = React.createRef();
  const [save_data, SetSaveData] = useState(initial_value);
  const [map_modal, SetMapModal] = useState({
    is_open: false,
    data: {},
    from: "",
    latitude: "",
    longitude: "",
    address: "",
  });
  const [isDropdownOpen, setDropdownOpen] = useState(null);
  const [country_options, SetCountryOptions] = useState(
    context.app_data.country_data
  );
  const [state_options, SetStateOptions] = useState(
    context.app_data.state_data
  );
  const [errors, setErrors] = useState({});

  useEffect(() => {
    SetCountryOptions(context.app_data.country_data);
    SetStateOptions(context.app_data.state_data)
    SetSaveData({
      brand_name: "",
      outlet_name: "",
      outlet_map_data: {},
      channel_data: {
        id: "2",
        name: "1SuperApp Market Place",
      },
      brand_image: [],
      banner_image: [],
      door_no: "",
      address: "",
      mobile: "",
      city: "",
      country: {
        id: 99,
        iso: "IN",
        name: "INDIA",
        nicename: "India",
        iso3: "IND",
        numcode: 356,
        phonecode: 91,
        value: 99,
        label: "India",
      },
      state: {
        id: 31,
        name: "TamilNadu",
      },
      pincode: "",
      approx_preparation_time: "20",
      cod: 0,
    });
  }, []);

  const BootstrapSelect = ({
    optionName,
    value,
    options,
    placeholder,
    isMultiSelect = true,
    callBack,
    haveSearch = false,
    disabled = false,
    isDropdownOpen,
    setDropdownOpen,
  }: any) => {
    let isOpen = isDropdownOpen === optionName;

    const dropdownRef = useRef(null);

    // useEffect(() => {
    //   const handleClickOutside = (event : any) => {
    //     console.log("dropdownRef.current :",dropdownRef.current)
    //     console.log("dropdownRef.current.contains(event.target) :",dropdownRef.current.contains(event.target))
    //     if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
    //       // setIsOpen(null);
    //       setDropdownOpen(null)
    //       // setTimeout(() => setDropdownOpen(null), 100);
    //       // isOpen = false;

    //     }
    //   };
    //   document.addEventListener("mousedown", handleClickOutside);

    //   return () => {
    //     // Cleanup event listener when component unmounts
    //     document.removeEventListener("mousedown", handleClickOutside);
    //   };
    // }, []);

    const [selectedOptions, setSelectedOptions]: any = useState([]);
    // const [selectedOptions, setSelectedOptions] : any = useState(isMultiSelect ? [] : null);
    const [searchQuery, setSearchQuery] = useState("");

    const toggleDropdown = (e) => {
      e.stopPropagation();
      // setDropdownOpen(!isDropdownOpen)
      setDropdownOpen(isOpen ? null : optionName);
      setSearchQuery("");
    };

    const handleOptionClick = (option: any, e: any) => {
      e.stopPropagation();
      console.log("selected option: ", optionName);
      if (isMultiSelect) {
        // For multi-select, add the option only if it's not already selected
        if (
          !selectedOptions.some(
            (selected: any) => selected.value === option.value
          )
        ) {
          setSelectedOptions([...selectedOptions, option]);
        }
      } else {
        // For single-select, replace the selected option
        setSelectedOptions([option]);
        setDropdownOpen(null); // Close dropdown after selecting an option
      }
      let send_data = {
        data: option,
      };
      callBack(send_data);
    };

    const handleRemoveOption = (option: any) => {
      setSelectedOptions(
        selectedOptions.filter(
          (selected: any) => selected.value !== option.value
        )
      );
    };

    const handleSearch = (e: any) => setSearchQuery(e.target.value);

    let filteredOptions;

    if (options[0]?.hasOwnProperty("nicename")) {
      filteredOptions = options.filter((option: any) =>
        option?.nicename?.toLowerCase().includes(searchQuery.toLowerCase())
      );
    } else {
      filteredOptions = options.filter((option: any) =>
        option?.name?.toLowerCase().includes(searchQuery.toLowerCase())
      );
    }

    // useEffect(() => {
    //   // const handleClickOutside = (event) => {
    //   //     setDropdownOpen(null)
    //   // };
    //   const handleClickOutside = (event : any) => {
    //     if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
    //       setDropdownOpen(null);
    //     }
    //   };

    //   document.addEventListener("mousedown", handleClickOutside);
    //   return () => document.removeEventListener("mousedown", handleClickOutside);
    // }, [setDropdownOpen]);

    return (
      <>
        <div className="dropdown" ref={dropdownRef} key={optionName}>
          <div
            className="form-control radius-10 outside d-flex align-items-center custom-select"
            onClick={(e) => {
              if (!disabled) {
                toggleDropdown(e);
              }
            }}
            style={{ cursor: "pointer" }}
          >
            <div>
              <span className="select-label">
                {value?.name ? value?.name : value?.nicename}
              </span>
              <span className="floating-custom-label">{optionName}</span>
            </div>

            <div className="ms-auto">
              <img
                src={
                  AppConfig.CDN_Media_url +
                  "b1b871802f6048caa74336bf068322cb.svg"
                }
                className="w-10px"
              />
            </div>
          </div>

          {isOpen && (
            <>
              <ul
                className="dropdown-menu show pt-2 mt-2"
                style={{ width: "100%", padding: "0" }}
              >
                {haveSearch && (
                  <input
                    type="text"
                    className="mx-2 dropdown-search"
                    placeholder="Search..."
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                  />
                )}
                {filteredOptions.length > 0 ? (
                  filteredOptions.map((option: any) => (
                    <li key={option.value} className="py-1">
                      <button
                        className="dropdown-item"
                        onClick={(e) => handleOptionClick(option, e)}
                      >
                        {option.name ? option.name : option.nicename}
                      </button>
                    </li>
                  ))
                ) : (
                  <li className="center mb-2 dropdown-item text-muted">
                    No options found
                  </li>
                )}
              </ul>
            </>
          )}
        </div>
      </>
    );
  };

  function onFilesAdded(evt: any) {
    const files = evt.target.files;

    let old_value = save_data.brand_image;
    const array = fileListToArray(files, old_value);
    SetSaveData((prevValue: any) => {
      prevValue.brand_image = array;
      return { ...prevValue };
    });
  }

  function fileListToArray(list: any, value: any) {
    const old_value = value;
    const array = [];
    for (var i = 0; i < list.length; i++) {
      let data: any = {};
      data.file = list.item(i);
      data.url = URL.createObjectURL(list.item(i));
      data.upload_percentage = 0;
      old_value.push(data);
    }
    return old_value;
  }

  function openFileDialog(get_data: any) {
    if (get_data.from == "brand") {
      if (BrandImageInputRef.current != null) {
        BrandImageInputRef.current.click();
      }
    } else {
      if (BannerImageInputRef.current != null) {
        BannerImageInputRef.current.click();
      }
    }
  }

  async function create_brand() {
    console.log("Create Brand Save Data :", save_data);
    let pass_data : any = {
      post: {
        brand_name: save_data.brand_name,
        outlet_name: save_data.outlet_name,
        lat: save_data.outlet_map_data.lat,
        lon: save_data.outlet_map_data.lang,
        map_address: save_data.outlet_map_data.full_address_value,
        channel_id: save_data.channel_data.id,
        // banner_image: save_data.brand_image[0].file,
        door_no:save_data.door_no,
        address:save_data.address,
        mobile:save_data.mobile,
        city:save_data.city,
        country:save_data.country.id,
        state:save_data.state.id,
        pincode:save_data.pincode,
        approx_preparation_time:save_data.approx_preparation_time,
        cod:save_data.cod,
      },
    };
    if(save_data.brand_image.length > 0){
      pass_data.post.banner_image = save_data.brand_image[0].file;
    }
    console.log("pass_data :", pass_data);
    let response: any = await api(
      "/account/signup_v2",
      pass_data,
      false,
      true,
      "form_data"
    );
    console.log("/account/signup_v2 post response :", response);
    if (response.status_code == 200) {
      // let view_data_value = {
      //   name: save_data.brand_name,
      //   brand_name: "",
      //   type: "brand",
      // };
      // localStorage.setItem("view_data", JSON.stringify(view_data_value));
      SetSaveData(initial_value);
      let send_data = {
        from: "save",
        data: response.response.data,
      };
      props.call_back(send_data);
      // navigate("/brand/" + response.response.id);
    }
  }

  const validateForm = () => {
    let newErrors : any = {};

    if (!save_data?.brand_name?.trim()) newErrors.brand_name = "Brand name is required";
    if (!save_data?.outlet_name?.trim()) newErrors.outlet_name = "Outlet name is required";
    if (!save_data?.outlet_map_data?.full_address_value?.trim()) newErrors.location = "Outlet location is required";
    if (!save_data.mobile.match(/^[6789]\d{9}$/)) newErrors.mobile = "Invalid mobile number";
    // if (!save_data?.approx_preparation_time?.trim()) newErrors.app_pre_time = "Approx preperation time is required";
    if (!save_data?.approx_preparation_time?.trim()) newErrors.app_pre_time = "Approx preperation time is required";
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0; // Returns true if no errors
  };

  return (
    <div className="mt-3 bg-fff shadow p-3 h-100 mb-5">
      {/* Brand Name */}
      <div className="position-relative mt-4">
        <div className="d-flex w-100 border radius-6">
          <input
            type="text"
            className="outside w-100per border-none bg-none"
            value={save_data.brand_name}
            onChange={(e) => {
              SetSaveData((prevalue) => {
                prevalue.brand_name = e.target.value;
                // validateForm();
                return { ...prevalue };
              });
            }}
          />
          {/* <div className="center w-10per">
            <img
              src={
                AppConfig.CDN_Media_url + "b4bea0a7fdf01fb10dc6b969a6eb5dc7.svg"
              }
              className="w-20px"
            />
          </div> */}
        </div>
        <span className="floating-label-outside">Brand Name *</span>
      </div>
      {errors.brand_name && <p style={{ color: "red" }} className="p-2">{errors.brand_name}</p>}

      {/* Outlet Name */}
      <div className="d-flex align-items-center w-100">
        <div className="position-relative mt-4 w-100">
          <div className="d-flex w-100 border radius-6">
            <input
              type="text"
              className="outside w-100per border-none bg-none"
              value={save_data.outlet_name}
              onChange={(e) => {
                SetSaveData((prevalue) => {
                  prevalue.outlet_name = e.target.value;
                  // validateForm();
                  return { ...prevalue };
                });
              }}
            />
            {/* <div className="center w-20per">
              <img
                src={
                  AppConfig.CDN_Media_url +
                  "b4bea0a7fdf01fb10dc6b969a6eb5dc7.svg"
                }
                className="w-20px"
              />

              <div className="ms-2 p-2 ">
                <img
                  src={
                    AppConfig.CDN_Media_url +
                    "b1b871802f6048caa74336bf068322cb.svg"
                  }
                  className="w-14px"
                />
              </div>
            </div> */}
          </div>
          <span className="floating-label-outside">Outlet Name *</span>
        </div>
        {/* <div className="ms-2 mt-3 w-10per">
          <div className="locate-me mt-2 center">
            <img
              src={
                AppConfig.CDN_Media_url + "7767d0d4cacda6a7c1cee478a6cf447c.svg"
              }
              className="w-16px"
            />
          </div>
        </div> */}
      </div>
      {errors.outlet_name && <p style={{ color: "red" }} className="p-2">{errors.outlet_name}</p>}

      {/* Outlet Map Location */}
      <div
        className="d-flex align-items-center w-100"
        onClick={() => {
          SetMapModal((prevalue) => {
            prevalue.is_open = true;
            prevalue.from = "create";
            prevalue.latitude = save_data.outlet_map_data.lat;
            prevalue.longitude = save_data.outlet_map_data.lang;
            return { ...prevalue };
          });
        }}
      >
        <div className="position-relative mt-4 w-90">
          <input
            type="text"
            className="outside"
            value={save_data.outlet_map_data.full_address_value}
            onClick={() => {
              console.log("on outlet map location click");
            }}
          />
          <span className="floating-label-outside">Outlet Map Location *</span>
        </div>
        <div
          className="ms-2 mt-3 w-10per"
          onClick={() => {
            SetMapModal((prevalue) => {
              prevalue.is_open = true;
              prevalue.from = "create";
              prevalue.latitude = save_data.outlet_map_data.lat;
              prevalue.longitude = save_data.outlet_map_data.lang;
              return { ...prevalue };
            });
          }}
        >
          <div className="locate-me mt-2 center">
            <img
              src={
                AppConfig.CDN_Media_url + "0d86a456fb776908ed0c78d70df47ddb.svg"
              }
              className="w-20"
            />
          </div>
        </div>
      </div>
      {errors.location && <p style={{ color: "red" }} className="p-2">{errors.location}</p>}

      {/* Channel Name */}
      <div className="d-flex align-items-center w-100">
        <div className="position-relative mt-4 w-100">
          <div className="d-flex w-100 border radius-6">
            <input
              type="text"
              disabled={true}
              className="outside w-100per border-none bg-none"
              value={save_data.channel_data.name}
              onChange={(e) => {
                // SetSaveData((prevalue)=>{
                //   prevalue.outlet_name = e.target.value;
                //   return {...prevalue};
                // })
              }}
            />
            {/* <div className="center w-20per">
              <img
                src={
                  AppConfig.CDN_Media_url +
                  "b4bea0a7fdf01fb10dc6b969a6eb5dc7.svg"
                }
                className="w-20px"
              />

              <div className="ms-2 p-2 ">
                <img
                  src={
                    AppConfig.CDN_Media_url +
                    "b1b871802f6048caa74336bf068322cb.svg"
                  }
                  className="w-14px"
                />
              </div>
            </div> */}
          </div>
          <span className="floating-label-outside">Channel Name *</span>
        </div>
        {/* <div className="ms-2 mt-3 w-10per">
          <div className="locate-me mt-2 center">
            <img
              src={
                AppConfig.CDN_Media_url + "7767d0d4cacda6a7c1cee478a6cf447c.svg"
              }
              className="w-16px"
            />
          </div>
        </div> */}
      </div>

      {/* Brand Image */}
      <div className="position-relative mt-4 w-100">
        <div className="outside w-100">
          <input
            ref={BrandImageInputRef}
            className="outside FileInputNone"
            type="file"
            // multiple
            onChange={(evt) => {
              onFilesAdded(evt);
            }}
          />
          <div className="border radius-10 p-3 w-100">
            <div className="d-flex align-items-center">
              {save_data.brand_image.map((element: any, index: any) => (
                <div className="border radius-6 pre-img-size me-2" key={index}>
                  <img src={element.url} className="pre-img-size" />
                  <div
                    className="cursor text-center text-red over-img-close"
                    onClick={() => {
                      SetSaveData((prevValue: any) => {
                        let file_data = prevValue.brand_image;
                        file_data.splice(index, 1);
                        prevValue.brand_image = file_data;
                        return { ...prevValue };
                      });
                    }}
                  >
                    ×
                  </div>
                </div>
              ))}
              {save_data.brand_image.length == 0 && (
                <div
                  className="border radius-6 bg-gray pre-img-size center"
                  onClick={() => {
                    SetSaveData((prevValue: any) => {
                      if (prevValue.brand_image.length == 0) {
                        openFileDialog({ from: "brand" });
                      }
                      return { ...prevValue };
                    });
                  }}
                >
                  <img
                    src={
                      AppConfig.CDN_Media_url +
                      "47b9835dc6f13fad0481e35755afecd2.svg"
                    }
                    className="w-20"
                  />
                </div>
              )}
            </div>
          </div>

          <span className="floating-label-outside">Brand Image</span>
        </div>
      </div>

      {/* Banner Image */}
      {/* <div className="position-relative mt-4 w-100">
        <div className="outside w-100">
          <input
            ref={BannerImageInputRef}
            className="outside FileInputNone"
            type="file"
            // multiple
            onChange={(evt) => {
              onFilesAdded(evt);
            }}
          />
          <div className="border radius-10 p-3 w-100">
            <div className="d-flex align-items-center">
              {save_data?.banner_image?.map((element: any, index: any) => (
                <div className="border radius-6 pre-img-size me-2" key={index}>
                  <img src={element.url} className="pre-img-size" />
                  <div
                    className="cursor text-center text-red over-img-close"
                    onClick={() => {
                      SetSaveData((prevValue: any) => {
                        let file_data = prevValue.banner_image;
                        file_data.splice(index, 1);
                        prevValue.banner_image = file_data;
                        return { ...prevValue };
                      });
                    }}
                  >
                    ×
                  </div>
                </div>
              ))}
              {save_data?.banner_image?.length == 0 && (
                <div
                  className="border radius-6 bg-gray pre-img-size center"
                  onClick={() => {
                    SetSaveData((prevValue: any) => {
                      if (prevValue.banner_image.length == 0) {
                        openFileDialog({from:"banner"});
                      }
                      return { ...prevValue };
                    });
                  }}
                >
                  <img
                    src={
                      AppConfig.CDN_Media_url +
                      "47b9835dc6f13fad0481e35755afecd2.svg"
                    }
                    className="w-20"
                  />
                </div>
              )}
            </div>
          </div>

          <span className="floating-label-outside">Banner Image</span>
        </div>
      </div> */}

      {/* Door no */}
      <div className="align-items-center w-100">
        <div className="position-relative mt-4 w-100">
          <div className="d-flex w-100 border radius-6">
            <input
              type="text"
              className="outside w-100per border-none bg-none"
              value={save_data.door_no}
              onChange={(e) => {
                SetSaveData((prevalue) => {
                  prevalue.door_no = e.target.value;
                  return { ...prevalue };
                });
              }}
            />
          </div>
          <span className="floating-label-outside">Door No</span>
        </div>
      </div>

      {/* Address */}
      <div className="align-items-center w-100">
        <div className="position-relative mt-4 w-100">
          <div className="d-flex w-100 border radius-6">
            <input
              type="text"
              className="outside w-100per border-none bg-none"
              value={save_data.address}
              onChange={(e) => {
                SetSaveData((prevalue) => {
                  prevalue.address = e.target.value;
                  return { ...prevalue };
                });
              }}
            />
          </div>
          <span className="floating-label-outside">Address</span>
        </div>
      </div>

      {/* Mobile */}
      <div className="align-items-center w-100">
        <div className="position-relative mt-4 w-100">
          <div className="d-flex w-100 radius-6">
            <input
              type="number"
              maxLength={10}
              className="outside w-100per border-none bg-none"
              value={save_data.mobile}
              onChange={(e) => {
                SetSaveData((prevalue) => {
                  prevalue.mobile = e.target.value;
                  // validateForm();
                  return { ...prevalue };
                });
              }}
            />
          </div>
          <span className="floating-label-outside">Mobile *</span>
        </div>
      </div>
      {errors.mobile && <p style={{ color: "red" }} className="p-2">{errors.mobile}</p>}


      {/* city */}
      <div className="align-items-center w-100">
        <div className="position-relative mt-4 w-100">
          <div className="d-flex w-100 border radius-6">
            <input
              type="text"
              className="outside w-100per border-none bg-none"
              value={save_data.city}
              onChange={(e) => {
                SetSaveData((prevalue) => {
                  prevalue.city = e.target.value;
                  return { ...prevalue };
                });
              }}
            />
          </div>
          <span className="floating-label-outside">City</span>
        </div>
      </div>

      {/* countries */}
      <div className="align-items-center w-100">
        <div className="position-relative mt-4 me-1 w-100">
          <BootstrapSelect
            className="outside"
            options={country_options}
            // disabled={true}
            isDropdownOpen={isDropdownOpen}
            setDropdownOpen={setDropdownOpen}
            value={save_data?.country}
            placeholder="Select an option..."
            isMultiSelect={false}
            optionName="Country"
            callBack={(data: any) => {
              console.log("on select sector :", data);
              SetSaveData((prevalue) => {
                prevalue.country = data.data;
                return { ...prevalue };
              });
            }}
          />
        </div>
      </div>

      {/* states */}
      <div className="align-items-center w-100">
        <div className="position-relative mt-4 me-1 w-100">
          <BootstrapSelect
            className="outside"
            options={state_options}
            haveSearch={true}
            // disabled={true}
            value={save_data.state}
            isDropdownOpen={isDropdownOpen}
            setDropdownOpen={setDropdownOpen}
            isMultiSelect={false}
            placeholder="Select an option..."
            optionName="State"
            callBack={(data: any) => {
              console.log("on select sector :", data);
              SetSaveData((prevalue) => {
                prevalue.state = data.data;
                return { ...prevalue };
              });
            }}
          />
        </div>
      </div>

      {/* Pincode */}
      <div className="align-items-center w-100">
        <div className="position-relative mt-4 w-100">
          <div className="d-flex w-100 radius-6">
            <input
              type="number"
              className="outside w-100per border-none bg-none"
              value={save_data.pincode}
              onChange={(e) => {
                SetSaveData((prevalue) => {
                  prevalue.pincode = e.target.value;
                  return { ...prevalue };
                });
              }}
            />
          </div>
          <span className="floating-label-outside">Pincode</span>
        </div>
      </div>

      {/* Axpo Prepare time */}
      <div className="align-items-center w-100">
        <div className="position-relative mt-4 w-100">
          <div className="d-flex w-100 radius-6">
            <input
              type="number"
              className="outside w-100per border-none bg-none"
              value={save_data.approx_preparation_time}
              onChange={(e) => {
                SetSaveData((prevalue) => {
                  prevalue.approx_preparation_time = e.target.value.trim();
                  return { ...prevalue };
                });
              }}
            />
          </div>
          <span className="floating-label-outside">
            Approx Preparation Time
          </span>
        </div>
      </div>
      {errors.app_pre_time && <p style={{ color: "red" }} className="p-2">{errors.app_pre_time}</p>}

      {/* cod */}
      <div className="col-12 col-md-6 my-3">
        <label className="form-label d-flex align-items-center mb-3 cursor">
          <input
            type="checkbox"
            className="form-check-input me-2"
            name={"filter_category"}
            value={save_data.cod}
            checked={save_data?.cod == 1 ? true : false}
            onChange={(event) => {
              SetSaveData((prevalue) => {
                if (event.target.checked) {
                  prevalue.cod = 1;
                } else {
                  prevalue.cod = 0;
                }
                return { ...prevalue };
              });
            }}
          />
          <p className="capitalize mb-0">Allow Cash On Delivery Order</p>
        </label>
      </div>

      <button
        className="primary-button mt-3"
        onClick={() => {
          console.log("on create :",save_data);
          if (validateForm()) {
            // alert("Form submitted successfully!");
            create_brand();
          }else{
            console.log("fill the details")
            toast.error("Fill required fields");
          }
        }}
      >
        Create
      </button>

      {map_modal.is_open && (
        <LocationPicker
          open={map_modal.is_open}
          from={map_modal.from}
          lat={map_modal.latitude}
          long={map_modal.longitude}
          address={map_modal.address}
          picked_lat_long={{
            lat: map_modal.latitude,
            long: map_modal.longitude,
          }}
          picked_address_value={map_modal.address}
          close={(data: any) => {
            console.log("Location picker on close :", data);
            SetMapModal((prevalue) => {
              prevalue.is_open = false;
              return { ...prevalue };
            });
            if (data.action == "save") {
              SetSaveData((prevalue: any) => {
                prevalue.outlet_map_data = data.value;
                // validateForm();
                return { ...prevalue };
              });
              SetMapModal((prevValue: any) => {
                prevValue.latitude = data.value.lat;
                prevValue.longitude = data.value.lang;
                prevValue.address = data.value.full_address_value;
                return { ...prevValue };
              });
            }
          }}
        />
      )}
    </div>
  );
}
