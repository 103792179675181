import React, { useState, useEffect, useContext } from "react";
import { api } from "../../../utils/Api";
import toast from "react-hot-toast";
import { AppHelper } from "../../../utils/Helper";
import { AppConfig } from "../../../config";
import DataContext from "../../../utils/Context/DataContext";
import { useOutletContext, useParams } from "react-router-dom";

export default function PackageCharge(props: any) {
  const context: any = useContext(DataContext);
  const OutletContext = useOutletContext();
  const [id_value, SerIdValue] = useState(useParams());
  const [width, SetWidth] = useState(innerWidth);
  const [save_data, SetSaveData] = useState({
    type: 1,
    gst: "",
    amount: "",
  });
  const [is_edit, SetIsEdit] = useState(true);

  useEffect(() => {
    console.log("PackageCharge on mount:", props);
    get_charges();
  }, []);

  async function get_charges() {
    let pass_data = {
      get: {
        brand: id_value.brand_id,
        outlet_id: id_value.outlet_id,
      },
    };
    let response: any = await api(
      "/brand/get_order_packaging_charges",
      pass_data
    );
    console.log(
      "/brand/get_order_packaging_charges response :",
      response.response
    );
    if (response.status_code == 200) {
      let r_data = response.response;
      if (r_data.status) {
        if (r_data.hasOwnProperty("type")) {
          SetSaveData((prevalue: any) => {
            prevalue.type = r_data.type;
            prevalue.amount = r_data.amount;
            prevalue.gst = r_data.gst;
            return { ...prevalue };
          });
        } else {
          SetIsEdit(true);
        }
      }
    }
  }

  async function update() {
    let pass_data = {
      get: {
        brand: id_value.brand_id,
      },
      post: {
        outlet_id: id_value.outlet_id,
        type: save_data.type,
        gst: save_data.gst,
        amount: save_data.amount,
      },
    };
    console.log("pass_data :", pass_data);

    let data_res: any = await api(
      "/brand/save_order_packaging_charges",
      pass_data
    );
    console.log(
      "/brand/save_order_packaging_charges response :",
      data_res.response
    );
    if (data_res.status_code == 200) {
      if (data_res.response.status) {
        toast.success("Updated");
        SetIsEdit(true);
        get_charges();
      }
    }
  }

  return (
    <div className="mb-5">
      <div
        className={OutletContext ? "head-bar sticky-sm" : "head-bar  sticky-sm"}
        style={OutletContext == false ? { width: width } : {}}
      >
        <div className="d-flex align-items-center px-2">
          <div
            className="cursor"
            onClick={() => {
              window.history.back(-1);
            }}
          >
            <img
              src={
                AppConfig.CDN_Media_url + "6aee348fd24aa1bdf7042e6b70d1e2bc.svg"
              }
              className="type-img"
            />
          </div>
          <h6 className="mb-0 text-dark fw-bolder px-3">Packaging Charges</h6>
          <div className="ms-auto">
            {!is_edit && (
              <button
                className="btn btn-primary"
                onClick={() => {
                  SetIsEdit(true);
                }}
              >
                Edit
              </button>
            )}
          </div>
        </div>
      </div>
      <div
        className="mt-3 px-md-3 px-sm-0"
        style={
          width < 786
            ? OutletContext == false
              ? { width: width }
              : {}
            : OutletContext == false
            ? { width: width, paddingTop: "3rem" }
            : {}
        }
      >
        <div className="bg-fff p-2 radius">
          <p className="pt-2 pb-1 text-darkgray">Package Type</p>
          <div className="form-check mb-2">
            <input
              className="form-check-input"
              checked={save_data.type == "2"}
              type="radio"
              disabled={!is_edit}
              value={2}
              name="flexRadioDefault"
              id="flexRadioDefault1"
              onChange={(e) => {
                SetSaveData((prevalue: any) => {
                  prevalue.type = e.target.value;
                  return { ...prevalue };
                });
              }}
            />
            <label
              className="form-check-label text-black"
              htmlFor="flexRadioDefault1"
            >
              Fixed
            </label>
          </div>
          <div className="form-check mb-2">
            <input
              className="form-check-input"
              checked={save_data.type == "1"}
              type="radio"
              disabled={!is_edit}
              value={1}
              name="flexRadioDefault"
              id="flexRadioDefault2"
              onChange={(e) => {
                SetSaveData((prevalue: any) => {
                  prevalue.type = e.target.value;
                  return { ...prevalue };
                });
              }}
            />
            <label
              className="form-check-label text-black"
              htmlFor="flexRadioDefault2"
            >
              Percentage
            </label>
          </div>

          <div className="row align-items-center">
            <div className="col-10 row p-0">
              <div className="col-6 ps-0">
                <p className="ps-2 pb-1 text-darkgray">GST %</p>
                <input
                  type="number"
                  disabled={!is_edit}
                  className="form-control"
                  value={save_data.gst}
                  onChange={(e) =>
                    SetSaveData((prevalue) => {
                      prevalue.gst = e.target.value;
                      return { ...prevalue };
                    })
                  }
                />
              </div>
              <div className="col-6 ps-0">
                <p className="ps-2 pb-1 text-darkgray">Amount *</p>
                <input
                  type="number"
                  disabled={!is_edit}
                  className="form-control"
                  value={save_data.amount}
                  onChange={(e) =>
                    SetSaveData((prevalue) => {
                      prevalue.amount = e.target.value;
                      return { ...prevalue };
                    })
                  }
                />
              </div>
            </div>
          </div>
          {/* {is_edit && ( */}
            <div className="text-end mt-4">
              {/* <button
                type="button"
                className="btn bg-prime text-white btn-radius px-3 btn-sm"
                onClick={() => {
                  SetIsEdit(true);
                }}
              >
                Cancel
              </button> */}
              <button
                type="button"
                className="btn p-2 bg-prime text-white btn-radius px-3 w-100"
                onClick={() => {
                  if (
                    String(save_data.type)?.trim() !== "" &&
                    String(save_data.amount)?.trim() !== ""
                  ) {
                    update();
                  } else {
                    toast.error("Fill required fields");
                  }
                }}
              >
                Save
              </button>
            </div>
          {/* )} */}
        </div>
      </div>
    </div>
  );
}
