import React, {
  useState,
  useEffect,
  useContext,
  useRef,
  useCallback,
} from "react";
import {
  AntDesign,
  Ionicons,
  MaterialCommunityIcons,
} from "@expo/vector-icons";
import {
  Link,
  NavLink,
  useNavigate,
  useOutletContext,
  useParams,
} from "react-router-dom";
import { api } from "../../../utils/Api";
import { AppConfig } from "../../../config";
import InfiniteScroll from "react-infinite-scroll-component";
import Skeleton from "react-loading-skeleton";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Menu,
  MenuItem,
  Modal,
} from "@material-ui/core";
import VisibilitySensor from "react-visibility-sensor";
import { AppHelper } from "../../../utils/Helper";
import toast from "react-hot-toast";
import SwipeableBottomSheet from "@geomatico/react-swipeable-bottom-sheet";
import * as Scroll from "react-scroll";
// import ModalGallery from "./ModalGallery";
import ModalAddGallery from "../Gallery/ModalAddGallery";
import DataContext from "../../../utils/Context/DataContext";

const initial_value = {
  collection_name: "",
  // outlet_channel_id: 1,
  sector: {},
  category: {},
  product_name: "",
  product_description: "",
  diet_type: {},
  selected_files: [],
  contains_packs: null,
  contains_quantity: null,
  contains_units: null,
  countries: {},
  states: {},
  inventory_based: {},
  inventory_type: {},
  batch_number: "",
  manufature_date: "",
  expiry_date: "",
  tax: {
    mrp: "",
    igst: "",
    sgst: "",
    cgst: "",
  },
  maximium_pricing: {
    selling_price: "",
    igst: "",
    sgst: "",
    cgst: "",
  },
  pricing_without_tax: {
    selling_price: "",
    offer_price: "",
    total: "",
  },
  pricing_with_tax: {
    selling_price: "",
    offer_price: "",
    total: "",
  },
  pc_type: 2,
  pc_gst: "",
  pc_amount: "",
  price_type: {
    id: 1,
    name: "Static Price",
  },
  strike_price_without_tax: "",
  strike_price_tax_amount: "",
  strike_price_with_tax: "",
  stock: "",
};

export default function QuickCreation(props: any) {
  const [id_value, SerIdValue] = useState(useParams());
  const [main_data, SetMainData]: any = useState([]);
  const [single_product_active, setSingleProductActive] = useState(true);
  const [recommend, SetRecommend] = useState(false);
  const fileInputRef = React.createRef();
  const [visibility_sensor_active, SetVisibilitySensorActive] = useState(true);
  const [have_more_page_no, SetHaveMorePageNo] = useState(1);
  const [more_has_more, SetMoreHasMore] = useState(true);
  const [image_base_url, SetImgBaseUrl]: any = useState("");
  const [is_loading, SetIsLoading] = useState(true);
  const [outlet_list, SetOutletList]: any = useState([]);
  const [channel_list, SetChannelList]: any = useState([]);
  const [selected_outlet, SetSelectedOutlet]: any = useState({});
  const [selected_channel, SetSelectedChannel]: any = useState({});
  const OutletContext = useOutletContext();
  const [width, SetWidth] = useState(innerWidth);
  const [all_category_options, SetAllCategoryOptions] = useState([]);
  const [diet_options, SetDiteOptions] = useState([]);
  const [sector_options, SetSectorOptions] = useState([]);
  const [category_options, SetCategoryOptions] = useState([]);
  const [inventory_options, SetInventoryOptions] = useState([
    { name: "Yes", id: "1" },
    { name: "No", id: "2" },
  ]);
  const [inventory_type_options, SetInventoryTypeOptions] = useState([]);
  const [country_options, SetCountryOptions] = useState([]);
  const [state_options, SetStateOptions] = useState([]);
  const [pc_bottom_sheet_data, SetPCBottomSheetData] = useState({
    is_open: false,
    data: {},
  });
  const [pc_edit_bottom_sheet_data, SetPCEditBottomSheetData] = useState({
    is_open: false,
    data: {
      pc_type: 0,
      pc_gst: "",
      pc_amount: "",
    },
    all_data: {},
  });

  const [product_detail_bottom_sheet_data, SetProductDetailBottomSheetData] =
    useState({
      is_open: false,
      all_data: {},
    });

  // const [edit_data , SetEditData] = useState({
  //   pc_type : null,

  // })

  // const [save_data, SetSaveData] = useState(initial_value);

  // const [collection_data, SetCollectionData] = useState("");
  const [save_data, SetSaveData] = useState(initial_value);
  const [uncheck_radio_value, SetUncheckRadioValue] = useState({
    checked_value: "",
    other_value: "",
  });
  const [collection_uncheck_radio_value, SetCollectionUncheckRadioValue] =
    useState({
      checked_value: "",
      other_value: "",
    });
  const [collection_bottom_sheet_data, SetCollectionBottomSheetData] = useState(
    {
      is_open: false,
      data: {},
    }
  );
  const [bottom_sheet_data, SetBottomSheetData] = useState({
    is_open: false,
    data: {},
  });
  const [open_collection_menu, SetOpenCollectionMenu]: any = useState(null);
  const [selected_index, SetSelectedIndex] = useState(1);

  const [isDropdownOpen, setDropdownOpen] = useState(null);
  const [show_diet, SetShowDiet] = useState(false);
  const [modal_gallery, SetModalGallery] = useState({
    is_open: false,
    data: {},
    selected_images: [],
  });
  const context: any = useContext(DataContext);
  const numInputsRef = useRef([]);
  // const dropdownRef = useRef(null);
  const [price_type_options, SetPriceTypeOptions] = useState([]);

  useEffect(() => {
    get_init();
    get_outlets_and_channels();
    // get_collections();

    const preventScroll = (event: any) => {
      if (
        numInputsRef.current.some((input) => document.activeElement === input)
      ) {
        event.preventDefault();
      }
    };

    // Attach event listener to window
    window.addEventListener("wheel", preventScroll, { passive: false });
    Scroll.Events.scrollEvent.register(
      "begin",
      function (to: any, element: any) {
        console.log("Scrolling to:", to); // Event before scrolling
        SetVisibilitySensorActive(false);
      }
    );

    Scroll.Events.scrollEvent.register("end", function (to: any, element: any) {
      console.log("Finished scrolling to:", to); // Event after scrolling
      SetVisibilitySensorActive(true);
    });

    const handleClickOutside = (event: any) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        // setIsOpen(false);
        setDropdownOpen(null);
      }
    };
    // document.addEventListener("mousedown", handleClickOutside);

    return () => {
      // Cleanup event listener when component unmounts
      window.removeEventListener("wheel", preventScroll);
      // document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (pc_bottom_sheet_data.is_open) {
      context.SetAppData((prevValue: any) => {
        prevValue.scroll_locked++;
        return { ...prevValue };
      });
      SetSaveData({
        collection_name: "",
        // outlet_channel_id: 1,
        sector: {},
        category: {},
        product_name: "",
        product_description: "",
        diet_type: {},
        selected_files: [],
        contains_packs: null,
        contains_quantity: null,
        contains_units: null,
        countries: {},
        states: {},
        inventory_based: {},
        inventory_type: {},
        batch_number: "",
        manufature_date: "",
        expiry_date: "",
        tax: {
          mrp: "",
          igst: "",
          sgst: "",
          cgst: "",
        },
        maximium_pricing: {
          selling_price: "",
          igst: "",
          sgst: "",
          cgst: "",
        },
        pricing_without_tax: {
          selling_price: "",
          offer_price: "",
          total: "",
        },
        pricing_with_tax: {
          selling_price: "",
          offer_price: "",
          total: "",
        },
        pc_type: 2,
        pc_gst: "",
        pc_amount: "",
        price_type: {
          id: 1,
          name: "Static Price",
        },
        strike_price_without_tax: "",
        strike_price_tax_amount: "",
        strike_price_with_tax: "",
        stock: "",
      });
    } else {
      context.SetAppData((prevValue: any) => {
        if (prevValue.scroll_locked > 0) {
          prevValue.scroll_locked--;
        }
        return { ...prevValue };
      });
      SetSaveData({
        collection_name: "",
        // outlet_channel_id: 1,
        sector: {},
        category: {},
        product_name: "",
        product_description: "",
        diet_type: {},
        selected_files: [],
        contains_packs: null,
        contains_quantity: null,
        contains_units: null,
        countries: {},
        states: {},
        inventory_based: {},
        inventory_type: {},
        batch_number: "",
        manufature_date: "",
        expiry_date: "",
        tax: {
          mrp: "",
          igst: "",
          sgst: "",
          cgst: "",
        },
        maximium_pricing: {
          selling_price: "",
          igst: "",
          sgst: "",
          cgst: "",
        },
        pricing_without_tax: {
          selling_price: "",
          offer_price: "",
          total: "",
        },
        pricing_with_tax: {
          selling_price: "",
          offer_price: "",
          total: "",
        },
        pc_type: 2,
        pc_gst: "",
        pc_amount: "",
        price_type: {
          id: 1,
          name: "Static Price",
        },
        strike_price_without_tax: "",
        strike_price_tax_amount: "",
        strike_price_with_tax: "",
        stock: "",
      });
    }
  }, [pc_bottom_sheet_data.is_open]);

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  async function get_init() {
    let pass_data: any = {
      get: {
        brand: id_value.brand_id,
        // outlet_id: outlet_value.id,
        outlet_id: id_value?.outlet_id,
      },
    };

    if (id_value.hasOwnProperty("outlet_id")) {
      pass_data.get.outlet = id_value?.outlet_id;
    }

    let response: any = await api(
      "/brand/quick_product_create_init",
      pass_data
    );
    console.log(
      "/brand/quick_product_create_init response :",
      response.response
    );
    if (response.status_code == 200) {
      let sectors: any = response.response.sectors;
      let category: any = response.response.category;
      let countries: any = response.response.countries;
      let states: any = response.response.states;
      let diet_type: any = response.response.diet_type;
      let inven_type: any = response.response.inventory_types;
      SetSectorOptions(sectors);
      SetAllCategoryOptions(category);
      SetCountryOptions(countries);
      SetStateOptions(states);
      SetDiteOptions(diet_type);
      SetInventoryTypeOptions(inven_type);
    }
  }

  async function get_outlets_and_channels() {
    let pass_data: any = {
      get: {
        brand: id_value.brand_id,
        outlet_id: id_value?.outlet_id,
        // outlet_id :
      },
    };
    // if(id_value.hasOwnProperty("outlet_id")){
    //   pass_data.get.outlet  = id_value?.outlet_id;
    // }
    if (id_value.hasOwnProperty("outlet_id")) {
      pass_data.get.outlet = id_value?.outlet_id;
    }
    let response: any = await api("/brand/outlet_channel_list", pass_data);
    console.log("/brand/outlet_channel_list response :", response.response);
    if (response.status_code == 200) {
      let outlet_data: any = response.response.outlets;
      outlet_data.map((ele: any, index: any) => {
        ele.lable = ele.name;
        ele.value = ele.id;
        if (id_value.hasOwnProperty("outlet_id")) {
          if (ele.id == id_value.outlet_id) {
            SetSelectedOutlet(ele);
            ele.channels.map((c_ele: any, c_index: any) => {
              c_ele.lable = c_ele.name;
              c_ele.value = c_ele.id;
              if (c_index == 0) {
                SetSelectedChannel(c_ele);
              }
            });
            SetChannelList(ele.channels);
          }
        } else {
          if (index == 0) {
            SetSelectedOutlet(ele);
            ele.channels.map((c_ele: any, c_index: any) => {
              c_ele.lable = c_ele.name;
              c_ele.value = c_ele.id;
              if (c_index == 0) {
                SetSelectedChannel(c_ele);
              }
            });
            SetChannelList(ele.channels);
          }
        }
      });
      SetOutletList(outlet_data);
      scrollToTop();
      SetSelectedOutlet((prevalue: any) => {
        let send_data = {
          outlet_value: prevalue,
        };
        get_collections(send_data);
        return { ...prevalue };
      });

      let price_data = response.response.price_type;
      SetPriceTypeOptions(price_data);
    }
  }

  async function get_collections(get_data: any) {
    let pass_data: any = {
      get: {
        brand: id_value.brand_id,
        direct: 1,
        // outlet_id: id_value?.outlet_id,
        // outlet_id : selected_outlet.id
        outlet_id: get_data.outlet_value.id,
      },
    };
    if (id_value.hasOwnProperty("outlet_id")) {
      pass_data.get.outlet = id_value?.outlet_id;
    }
    let response: any = await api("/brand/collections", pass_data);
    console.log("/brand/collections response :", response.response);
    if (response.status_code == 200) {
      process_main_data(response.response.data);
    }
  }

  function process_main_data(get_data: any) {
    console.log("process_main_data get_data :", get_data);
    let collection_value = get_data;
    let save_data1: any = [];
    collection_value.map((ele: any, index: any) => {
      console.log("process_main_data ele :", ele);
      let push_data = {
        collection_id: ele.id,
        collection_parent_id: ele.parent_id,
        collection_name: ele.name,
        collection_is_checked: false,
        have_produts: false,
        products_loading: false,
        products: [],
        show_add_product: false,
        is_viewed: false,
        show_skeleton: true,
      };
      if (index == 0) {
        SetSelectedOutlet((prevalue: any) => {
          get_collection_product(push_data, push_data, false, prevalue);
          return { ...prevalue };
        });
        // get_collection_product(push_data, push_data, false);
      }
      save_data1.push(push_data);
    });
    // if (collection_value.length > 0) {
      save_data1.push({
        collection_id: 0,
        collection_parent_id: 0,
        collection_name: "More",
        have_produts: false,
        products_loading: false,
        products: [],
        is_viewed: false,
        show_add_product: false,
        show_skeleton: true,
      });
    // }
    let send_data = {};
    console.log("process_main_data save_data1 : ", save_data1);
    console.log(
      "process_main_data save_data1 length: ",
      Object.keys(save_data1).length
    );
    SetMainData(save_data1);
    SetIsLoading(false);
    if (save_data1.length == 0) {
      SetPCBottomSheetData((prevalue: any) => {
        prevalue.is_open = true;
        prevalue.data = {
          c_from: "new",
          key: 0,
          index: 0,
          collection_id: 0,
          main_data_value: main_data,
        };
        return { ...prevalue };
      });
    }
  }

  const BootstrapSelect = ({
    optionName,
    value,
    options,
    placeholder,
    isMultiSelect = true,
    callBack,
    haveSearch = false,
    disabled = false,
    isDropdownOpen,
    setDropdownOpen,
  }: any) => {
    let isOpen = isDropdownOpen === optionName;

    const dropdownRef = useRef(null);

    // useEffect(() => {
    //   const handleClickOutside = (event : any) => {
    //     console.log("dropdownRef.current :",dropdownRef.current)
    //     console.log("dropdownRef.current.contains(event.target) :",dropdownRef.current.contains(event.target))
    //     if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
    //       // setIsOpen(null);
    //       setDropdownOpen(null)
    //       // setTimeout(() => setDropdownOpen(null), 100);
    //       // isOpen = false;

    //     }
    //   };
    //   document.addEventListener("mousedown", handleClickOutside);

    //   return () => {
    //     // Cleanup event listener when component unmounts
    //     document.removeEventListener("mousedown", handleClickOutside);
    //   };
    // }, []);

    const [selectedOptions, setSelectedOptions]: any = useState([]);
    // const [selectedOptions, setSelectedOptions] : any = useState(isMultiSelect ? [] : null);
    const [searchQuery, setSearchQuery] = useState("");

    const toggleDropdown = (e) => {
      e.stopPropagation();
      // setDropdownOpen(!isDropdownOpen)
      setDropdownOpen(isOpen ? null : optionName);
      setSearchQuery("");
    };

    const handleOptionClick = (option: any, e: any) => {
      e.stopPropagation();
      console.log("selected option: ", optionName);
      if (isMultiSelect) {
        // For multi-select, add the option only if it's not already selected
        if (
          !selectedOptions.some(
            (selected: any) => selected.value === option.value
          )
        ) {
          setSelectedOptions([...selectedOptions, option]);
        }
      } else {
        // For single-select, replace the selected option
        setSelectedOptions([option]);
        setDropdownOpen(null); // Close dropdown after selecting an option
      }
      let send_data = {
        data: option,
      };
      callBack(send_data);
    };

    const handleRemoveOption = (option: any) => {
      setSelectedOptions(
        selectedOptions.filter(
          (selected: any) => selected.value !== option.value
        )
      );
    };

    const handleSearch = (e: any) => setSearchQuery(e.target.value);

    let filteredOptions;

    if (options[0]?.hasOwnProperty("nicename")) {
      filteredOptions = options.filter((option: any) =>
        option?.nicename?.toLowerCase().includes(searchQuery.toLowerCase())
      );
    } else {
      filteredOptions = options.filter((option: any) =>
        option?.name?.toLowerCase().includes(searchQuery.toLowerCase())
      );
    }

    // useEffect(() => {
    //   // const handleClickOutside = (event) => {
    //   //     setDropdownOpen(null)
    //   // };
    //   const handleClickOutside = (event : any) => {
    //     if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
    //       setDropdownOpen(null);
    //     }
    //   };

    //   document.addEventListener("mousedown", handleClickOutside);
    //   return () => document.removeEventListener("mousedown", handleClickOutside);
    // }, [setDropdownOpen]);

    return (
      <>
        <div className="dropdown" ref={dropdownRef} key={optionName}>
          <div
            className="form-control radius-10 outside d-flex align-items-center custom-select"
            onClick={(e) => {
              if (!disabled) {
                toggleDropdown(e);
              }
            }}
            style={{ cursor: "pointer" }}
          >
            <div>
              <span className="select-label">
                {value?.name ? value?.name : value?.nicename}
              </span>
              <span className="floating-custom-label">{optionName}</span>
            </div>

            <div className="ms-auto">
              <img
                src={
                  AppConfig.CDN_Media_url +
                  "b1b871802f6048caa74336bf068322cb.svg"
                }
                className="w-10px"
              />
            </div>
          </div>

          {isOpen && (
            <>
              <ul
                className="dropdown-menu show pt-2 mt-2"
                style={{ width: "100%", padding: "0" }}
              >
                {haveSearch && (
                  <input
                    type="text"
                    className="mx-2 dropdown-search"
                    placeholder="Search..."
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                  />
                )}
                {filteredOptions.length > 0 ? (
                  filteredOptions.map((option: any) => (
                    <li key={option.value} className="py-1">
                      <button
                        className="dropdown-item"
                        onClick={(e) => handleOptionClick(option, e)}
                      >
                        {option.name ? option.name : option.nicename}
                      </button>
                    </li>
                  ))
                ) : (
                  <li className="center mb-2 dropdown-item text-muted">
                    No options found
                  </li>
                )}
              </ul>
            </>
          )}
        </div>
      </>
    );
  };

  const ProductCard = (pc_data: any) => {
    async function add_recommended(get_data: any) {
      let pass_data: any = {
        get: {
          brand: id_value.brand_id,
          // outlet_id: props.selected_outlet.id,
        },
        post: {},
      };
      if (get_data.hasOwnProperty("group_id")) {
        pass_data.post.group_id = get_data.group_id;
      }
      if (get_data.hasOwnProperty("product_id")) {
        pass_data.post.product_id = get_data.product_id;
      }
      let response: any = await api(
        "/brand/add_recommended_product",
        pass_data,
        false,
        false
      );
      console.log("response :", response);
      if (response.status_code == 200) {
        toast.success("Added to recommended");
        SetMainData((prevalue: any) => {
          // prevalue[get_data.index].collection_is_checked = true;
          prevalue[get_data.index].products[get_data.p_index].recommended =
            true;
          return [...prevalue];
        });
      } else {
        toast.error("Somthing went ");
      }
    }

    async function remove_recommended(get_data: any) {
      let pass_data: any = {
        get: {
          brand: id_value.brand_id,
          // outlet_id: props.selected_outlet.id,
        },
        post: {},
      };
      if (get_data.hasOwnProperty("group_id")) {
        pass_data.post.group_id = get_data.group_id;
      }
      if (get_data.hasOwnProperty("product_id")) {
        pass_data.post.product_id = get_data.product_id;
      }
      let response: any = await api(
        "/brand/remove_recommended_product",
        pass_data,
        false,
        false
      );
      console.log("response :", response);
      if (response.status_code == 200) {
        toast.success("Recommended Removed");
        SetMainData((prevalue: any) => {
          prevalue[get_data.index].products[get_data.p_index].recommended =
            false;
          return [...prevalue];
        });
      } else {
        toast.error("Somthing went ");
      }
    }

    const get_diet_icon = (get_data: any) => {
      let return_data;

      get_data?.specifications?.map((ele: any) => {
        if (
          ele?.id == AppConfig.diet_type.diet_type &&
          ele.hasOwnProperty("values")
        ) {
          if (ele?.values[0]?.id == AppConfig.diet_type.vegetarian) {
            return_data = (
              <img
                src={
                  AppConfig.CDN_Image_url +
                  "e301ce3af353aab421a2f76c898a439d.png"
                }
                className="w-10px me-2"
              />
            );
          }
          if (ele?.values[0]?.id == AppConfig.diet_type.non_vegetarian) {
            return_data = (
              <img
                src={
                  AppConfig.CDN_Image_url +
                  "5c7a11ae863a9b5cfa0e1844c7a186b9.png"
                }
                className="w-10px me-2"
              />
            );
          }
          if (ele?.values[0]?.id == AppConfig.diet_type.eggetarian) {
            return_data = (
              <img
                src={
                  AppConfig.CDN_Image_url +
                  "fea28c272b8bbfb74f54b073bea5cfaa.png"
                }
                className="w-10px me-2"
              />
            );
          }
          if (ele?.values[0]?.id == AppConfig.diet_type.vegan) {
            return_data = (
              <img
                src={
                  AppConfig.CDN_Image_url +
                  "1e2d32e8b1e3f2fd5eb65c3b8957674e.png"
                }
                className="w-10px me-2"
              />
            );
          }
        }
      });
      return return_data;
    };

    return (
      <div className="">
        {/* px-3 pt-3 */}
        <div className="card radius-10">
          <div
            className="p-2 pb-1 d-flex align-items-center"
            onClick={() => {
              SetProductDetailBottomSheetData((prevalue) => {
                prevalue.is_open = true;
                prevalue.all_data = pc_data.data;
                return { ...prevalue };
              });
            }}
          >
            <div className="w-70per ps-2">
              {/* <img
                src={
                  AppConfig.CDN_Image_url +
                  "e301ce3af353aab421a2f76c898a439d.png"
                }
                className="w-10px me-2"
              /> */}
              {get_diet_icon(pc_data?.data)}
              <div className="loc-head">{pc_data?.data?.name}</div>
              <div className="d-flex align-items-center">
                {pc_data?.data?.type == "product" ? (
                  pc_data?.data?.show_price_without_gst ? (
                    <div className="loc-head pe-2 text-dark">
                      ₹{pc_data?.data?.price_without_gst}
                    </div>
                  ) : (
                    <div className="loc-head pe-2 text-dark">
                      ₹{pc_data?.data?.price_with_gst}
                    </div>
                  )
                ) : pc_data?.data?.price?.show_price_without_gst ? (
                  <div className="loc-head pe-2 text-dark">
                    ₹{pc_data?.data?.price?.price_without_gst}
                  </div>
                ) : (
                  <div className="loc-head pe-2 text-dark">
                    ₹{pc_data?.data?.price?.price_with_gst}
                  </div>
                )}

                {pc_data.data.type == "product" ? (
                  <div className="pop-font text-gray fs-11 strike">
                    ₹{pc_data?.data?.mrp}
                  </div>
                ) : (
                  <div className="pop-font text-gray fs-11 strike">
                    ₹{pc_data?.data?.price?.mrp}
                  </div>
                )}

                {pc_data?.data?.packaging_charges?.show_price_without_gst
                  ? pc_data?.data?.packaging_charges?.hasOwnProperty(
                      "show_amount_with_gst"
                    ) && (
                      <div className="ms-2 pop-font fs-11">
                        PC : ₹{" "}
                        {pc_data?.data?.packaging_charges?.show_amount_with_gst}
                      </div>
                    )
                  : pc_data?.data?.packaging_charges?.hasOwnProperty(
                      "show_amount"
                    ) && (
                      <div className="ms-2 pop-font fs-11">
                        PC : ₹ {pc_data?.data?.packaging_charges?.show_amount}
                      </div>
                    )}
              </div>
              <div className="v-small text-gray">
                {pc_data?.data?.description}
              </div>
            </div>
            <div className="w-30per">
              {pc_data?.data?.images?.length > 0 ? (
                <img
                  src={image_base_url + pc_data?.data?.images[0]}
                  className="added-prod-img"
                />
              ) : // <img
              //   src={
              //     AppConfig.CDN_Image_url +
              //     "62eeaf50fa0a9a609efe846632b0fc47.jpg"
              //   }
              //   className="added-prod-img"
              // />
              null}
            </div>
          </div>

          <div className="d-flex align-items-center p-2">
            <div
              className="text-center px-2 mx-auto "
              onClick={() => {
                if (id_value.hasOwnProperty("outlet_id")) {
                } else {
                  SetPCEditBottomSheetData((prevalue) => {
                    prevalue.is_open = true;
                    if (pc_data.data.packaging_charges.type == null) {
                      prevalue.data.pc_type = "1";
                    } else {
                      prevalue.data.pc_type =
                        pc_data.data.packaging_charges.type;
                    }
                    if (pc_data.data.packaging_charges.gst == null) {
                      prevalue.data.pc_gst = "";
                    } else {
                      prevalue.data.pc_gst = pc_data.data.packaging_charges.gst;
                    }
                    if (pc_data.data.packaging_charges.amount == null) {
                      prevalue.data.pc_amount = "";
                    } else {
                      prevalue.data.pc_amount =
                        pc_data.data.packaging_charges.amount;
                    }
                    prevalue.all_data = pc_data.data;
                    return { ...prevalue };
                  });
                }
              }}
            >
              <div className="fs-9px text-gray pb-1">Edit</div>
              <img
                src={
                  AppConfig.CDN_Media_url +
                  "21d3a9e5cedf31f69753210bf5015802.svg"
                }
                className="w-16px"
              />
            </div>
            <div className="border-right-h-auto"></div>

            <div className="text-center px-2">
              <div className="fs-9px text-gray pb-1">Product</div>
              <div
                className={
                  pc_data.data.type == "product"
                    ? "px-2 py-1 bg-light-prime radius-6 d-flex align-items-center cursor"
                    : "px-2 py-1 bg-light-gray radius-6 d-flex align-items-center cursor"
                }
              >
                {pc_data?.data?.type == "product" ? (
                  <div className="extra-small text-prime pe-1">Single</div>
                ) : (
                  <div className="extra-small text-prime pe-1">Group</div>
                )}
                <img
                  src={
                    pc_data?.data?.type == "product"
                      ? AppConfig.CDN_Media_url +
                        "da324fef11da122ab61d90c58d6b7c09.svg"
                      : AppConfig.CDN_Media_url +
                        "e380f40f4e8eed611d55f334ab6093e2.svg"
                  }
                  className="w-12px"
                />
              </div>
            </div>

            <div className="border-right-h-auto"></div>

            {/* <div className="text-center px-2 mx-auto">
              <div className="fs-9px text-gray pb-2">Category</div>
              <div className="extra-small">New</div>
            </div>

            <div className="border-right-h-auto"></div> */}

            <div
              className="text-center px-2  cursor"
              onClick={() => {
                // SetRecommend(!recommend);
                let send_data: any = {
                  index: pc_data?.index,
                  p_index: pc_data?.p_index,
                };
                if (pc_data?.data?.type == "product") {
                  send_data.product_id = pc_data?.data?.product_id;
                }
                if (pc_data?.data?.type == "product_group") {
                  send_data.group_id = pc_data?.data?.product_group_id;
                }
                if (id_value.hasOwnProperty("outlet_id")) {
                  console.log("in outlet recommended");
                } else {
                  if (pc_data?.data.recommended) {
                    remove_recommended(send_data);
                  } else {
                    add_recommended(send_data);
                  }
                }
              }}
            >
              <div className="fs-9px text-gray pb-1">Recommend</div>
              <img
                src={
                  pc_data?.data?.recommended
                    ? AppConfig.CDN_Image_url +
                      "20e23b68a95299d8de9012085f390e32.png"
                    : AppConfig.CDN_Image_url +
                      "44eb5be056f431b5695c87cba9df786a.png"
                }
                style={{
                  filter: "sepia(100%) hue-rotate(190deg) saturate(500%)",
                }}
                className="w-20px"
              />
            </div>

            <div className="border-right-h-auto"></div>

            <div className=" text-center px-2 mx-auto">
              <div className="fs-9px text-gray mt-1">In Stock</div>
              <label className="in-stock-switch cursor">
                <input
                  type="checkbox"
                  id={"avail" + pc_data?.p_index}
                  className="matching-group-switch"
                  checked={
                    pc_data?.data?.channel_switch === "on" ? true : false
                  }
                  disabled={
                    pc_data?.data?.channel_assigned == "unchecked"
                      ? true
                      : false
                  }
                  onChange={(event) => {
                    console.log("switch on change !more product check123");
                    on_available_change(
                      event,
                      pc_data?.data,
                      pc_data?.p_index,
                      pc_data?.ele
                    );
                  }}
                />
                <span className="slider"></span>
              </label>
            </div>
          </div>
          {pc_data?.data?.channel_unavailable_message && (
            <div className="bg-light-gray p-2 mt-1">
              <div className="sub-text text-dark">
                {pc_data?.data?.channel_unavailable_message}
              </div>
            </div>
          )}
        </div>
      </div>
    );
  };

  function isFormValid() {
    // if (
    //   Object.keys(save_data?.sector)?.length > 0 && // Sector selected
    //   Object.keys(save_data?.category)?.length > 0 && // Category selected
    //   save_data?.product_name?.trim() !== "" &&
    //   // save_data.product_description?.trim() !== "" &&
    //   // Object.keys(save_data?.diet_type)?.length > 0 && // Diet type selected
    //   // save_data?.selected_files?.length > 0 && // At least one file uploaded
    //   Object.keys(save_data?.countries)?.length > 0 && // Country selected
    //   // Object.keys(save_data?.states)?.length > 0 && // State selected
    //   // save_data?.manufature_date?.trim() !== "" &&
    //   // save_data?.expiry_date?.trim() !== "" &&
    //   // new Date(save_data?.manufature_date) >= new Date(save_data?.expiry_date) &&
    //   save_data?.tax?.mrp?.trim() !== "" &&
    //   // save_data?.tax?.igst?.trim() !== "" &&
    //   String(save_data?.maximium_pricing?.selling_price)?.trim() !== "" &&
    //   String(save_data?.pricing_without_tax?.selling_price)?.trim() !== ""
    //   // save_data?.pc_type > 0 &&
    //   // save_data?.pc_amount?.trim() !== ""
    // ) {
    //   return true;
    // } else {
    //   return false;
    // }

    if (save_data.price_type.id == 1) {
      if (
        Object.keys(save_data?.sector)?.length > 0 && // Sector selected
        Object.keys(save_data?.category)?.length > 0 && // Category selected
        save_data?.product_name?.trim() !== "" &&
        // save_data.product_description?.trim() !== "" &&
        // Object.keys(save_data?.diet_type)?.length > 0 && // Diet type selected
        // save_data?.selected_files?.length > 0 && // At least one file uploaded
        Object.keys(save_data?.countries)?.length > 0 && // Country selected
        // Object.keys(save_data?.states)?.length > 0 && // State selected
        // save_data?.manufature_date?.trim() !== "" &&
        // save_data?.expiry_date?.trim() !== "" &&
        // new Date(save_data?.manufature_date) >= new Date(save_data?.expiry_date) &&
        // save_data?.tax?.mrp?.trim() !== "" &&
        // save_data?.tax?.igst?.trim() !== "" &&
        // String(save_data?.maximium_pricing?.selling_price)?.trim() !== "" &&
        String(save_data?.pricing_without_tax?.selling_price)?.trim() !== ""
        // save_data?.pc_type > 0 &&
        // save_data?.pc_amount?.trim() !== ""
      ) {
        return true;
      } else {
        return false;
      }
    }

    if (save_data.price_type.id == 2) {
      if (
        Object.keys(save_data?.sector)?.length > 0 && // Sector selected
        Object.keys(save_data?.category)?.length > 0 && // Category selected
        save_data?.product_name?.trim() !== "" &&
        Object.keys(save_data?.countries)?.length > 0 && // Country selected
        //
        // save_data?.tax?.mrp?.trim() !== "" &&
        save_data?.stock?.trim() !== "" &&
        //
        // String(save_data?.maximium_pricing?.selling_price)?.trim() !== "" &&
        String(save_data?.pricing_without_tax?.selling_price)?.trim() !== ""
      ) {
        return true;
      } else {
        return false;
      }
    }

    if (save_data.price_type.id == 3 || save_data.price_type.id == 4) {
      if (save_data?.inventory_type?.id == 1) {
        if (
          Object.keys(save_data?.sector)?.length > 0 && // Sector selected
          Object.keys(save_data?.category)?.length > 0 && // Category selected
          save_data?.product_name?.trim() !== "" &&
          Object.keys(save_data?.countries)?.length > 0 && // Country selected
          save_data?.tax?.mrp?.trim() !== "" &&
          //
          Object.keys(save_data?.inventory_type)?.length > 0 &&
          save_data?.batch_number?.trim() !== "" &&
          //
          // String(save_data?.maximium_pricing?.selling_price)?.trim() !== "" &&
          String(save_data?.pricing_without_tax?.selling_price)?.trim() !== ""
        ) {
          return true;
        } else {
          return false;
        }
      } else {
        if (
          Object.keys(save_data?.sector)?.length > 0 && // Sector selected
          Object.keys(save_data?.category)?.length > 0 && // Category selected
          save_data?.product_name?.trim() !== "" &&
          Object.keys(save_data?.countries)?.length > 0 && // Country selected
          // save_data?.tax?.mrp?.trim() !== "" &&
          //
          Object.keys(save_data?.inventory_type)?.length > 0 &&
          save_data?.batch_number?.trim() !== "" &&
          //
          // String(save_data?.maximium_pricing?.selling_price)?.trim() !== "" &&
          String(save_data?.pricing_without_tax?.selling_price)?.trim() !== ""
        ) {
          return true;
        } else {
          return false;
        }
      }
    }

    if (save_data.price_type.id == 5 || save_data.price_type.id == 6) {
      if (save_data?.inventory_type?.id == 1) {
        if (
          Object.keys(save_data?.sector)?.length > 0 && // Sector selected
          Object.keys(save_data?.category)?.length > 0 && // Category selected
          save_data?.product_name?.trim() !== "" &&
          Object.keys(save_data?.countries)?.length > 0 && // Country selected
          save_data?.tax?.mrp?.trim() !== "" &&
          //
          Object.keys(save_data?.inventory_type)?.length > 0 &&
          save_data?.batch_number?.trim() !== "" &&
          save_data?.stock?.trim() !== "" &&
          //
          // String(save_data?.maximium_pricing?.selling_price)?.trim() !== "" &&
          String(save_data?.pricing_without_tax?.selling_price)?.trim() !== ""
        ) {
          return true;
        } else {
          return false;
        }
      } else {
        if (
          Object.keys(save_data?.sector)?.length > 0 && // Sector selected
          Object.keys(save_data?.category)?.length > 0 && // Category selected
          save_data?.product_name?.trim() !== "" &&
          Object.keys(save_data?.countries)?.length > 0 && // Country selected
          // save_data?.tax?.mrp?.trim() !== "" &&
          //
          Object.keys(save_data?.inventory_type)?.length > 0 &&
          save_data?.batch_number?.trim() !== "" &&
          save_data?.stock?.trim() !== "" &&
          //
          // String(save_data?.maximium_pricing?.selling_price)?.trim() !== "" &&
          String(save_data?.pricing_without_tax?.selling_price)?.trim() !== ""
        ) {
          return true;
        } else {
          return false;
        }
      }
    }
  }

  function isEditFormValid() {
    if (
      pc_edit_bottom_sheet_data?.data?.pc_type > 0 &&
      pc_edit_bottom_sheet_data?.data?.pc_amount?.trim() !== ""
    ) {
      return true;
    } else {
      return false;
    }
  }

  function isFormValidNew() {
    // if (
    //   save_data?.collection_name?.trim() !== "" &&
    //   Object.keys(save_data?.sector)?.length > 0 && // Sector selected
    //   Object.keys(save_data?.category)?.length > 0 && // Category selected
    //   save_data?.product_name?.trim() !== "" &&
    //   // save_data?.product_description?.trim() !== "" &&
    //   // Object.keys(save_data?.diet_type)?.length > 0 && // Diet type selected
    //   // save_data?.selected_files?.length > 0 && // At least one file uploaded
    //   Object.keys(save_data?.countries)?.length > 0 && // Country selected
    //   // Object.keys(save_data?.states)?.length > 0 && // State selected
    //   // save_data?.manufature_date?.trim() !== "" &&
    //   // save_data?.expiry_date?.trim() !== "" &&
    //   // new Date(save_data?.manufature_date) >= new Date(save_data?.expiry_date) &&
    //   save_data?.tax?.mrp?.trim() !== "" &&
    //   // save_data?.tax?.igst?.trim() !== "" &&
    //   String(save_data?.maximium_pricing?.selling_price)?.trim() !== "" &&
    //   String(save_data?.pricing_without_tax?.selling_price)?.trim() !== ""
    //   // save_data?.pc_type > 0 &&
    //   // save_data?.pc_amount?.trim() !== ""
    // ) {
    //   return true;
    // } else {
    //   return false;
    // }

    if (save_data.price_type.id == 1) {
      if (
        save_data?.collection_name?.trim() !== "" &&
        Object.keys(save_data?.sector)?.length > 0 && // Sector selected
        Object.keys(save_data?.category)?.length > 0 && // Category selected
        save_data?.product_name?.trim() !== "" &&
        Object.keys(save_data?.countries)?.length > 0 && // Country selected
        // save_data?.tax?.mrp?.trim() !== "" &&
        // String(save_data?.maximium_pricing?.selling_price)?.trim() !== "" &&
        String(save_data?.pricing_without_tax?.selling_price)?.trim() !== ""
      ) {
        return true;
      } else {
        return false;
      }
    }

    if (save_data.price_type.id == 2) {
      if (
        save_data?.collection_name?.trim() !== "" &&
        Object.keys(save_data?.sector)?.length > 0 && // Sector selected
        Object.keys(save_data?.category)?.length > 0 && // Category selected
        save_data?.product_name?.trim() !== "" &&
        Object.keys(save_data?.countries)?.length > 0 && // Country selected
        //
        // save_data?.tax?.mrp?.trim() !== "" &&
        save_data?.stock?.trim() !== "" &&
        //
        // String(save_data?.maximium_pricing?.selling_price)?.trim() !== "" &&
        String(save_data?.pricing_without_tax?.selling_price)?.trim() !== ""
      ) {
        return true;
      } else {
        return false;
      }
    }

    if (save_data.price_type.id == 3 || save_data.price_type.id == 4) {
      if (save_data?.inventory_type?.id == 1) {
        if (
          save_data?.collection_name?.trim() !== "" &&
          Object.keys(save_data?.sector)?.length > 0 && // Sector selected
          Object.keys(save_data?.category)?.length > 0 && // Category selected
          save_data?.product_name?.trim() !== "" &&
          Object.keys(save_data?.countries)?.length > 0 && // Country selected
          save_data?.tax?.mrp?.trim() !== "" &&
          //
          Object.keys(save_data?.inventory_type)?.length > 0 &&
          save_data?.batch_number?.trim() !== "" &&
          //
          // String(save_data?.maximium_pricing?.selling_price)?.trim() !== "" &&
          String(save_data?.pricing_without_tax?.selling_price)?.trim() !== ""
        ) {
          return true;
        } else {
          return false;
        }
      } else {
        if (
          save_data?.collection_name?.trim() !== "" &&
          Object.keys(save_data?.sector)?.length > 0 && // Sector selected
          Object.keys(save_data?.category)?.length > 0 && // Category selected
          save_data?.product_name?.trim() !== "" &&
          Object.keys(save_data?.countries)?.length > 0 && // Country selected
          // save_data?.tax?.mrp?.trim() !== "" &&
          //
          Object.keys(save_data?.inventory_type)?.length > 0 &&
          save_data?.batch_number?.trim() !== "" &&
          //
          // String(save_data?.maximium_pricing?.selling_price)?.trim() !== "" &&
          String(save_data?.pricing_without_tax?.selling_price)?.trim() !== ""
        ) {
          return true;
        } else {
          return false;
        }
      }
    }

    if (save_data.price_type.id == 5 || save_data.price_type.id == 6) {
      if (save_data?.inventory_type?.id == 1) {
        if (
          save_data?.collection_name?.trim() !== "" &&
          Object.keys(save_data?.sector)?.length > 0 && // Sector selected
          Object.keys(save_data?.category)?.length > 0 && // Category selected
          save_data?.product_name?.trim() !== "" &&
          Object.keys(save_data?.countries)?.length > 0 && // Country selected
          save_data?.tax?.mrp?.trim() !== "" &&
          //
          Object.keys(save_data?.inventory_type)?.length > 0 &&
          save_data?.batch_number?.trim() !== "" &&
          save_data?.stock?.trim() !== "" &&
          //
          // String(save_data?.maximium_pricing?.selling_price)?.trim() !== "" &&
          String(save_data?.pricing_without_tax?.selling_price)?.trim() !== ""
        ) {
          return true;
        } else {
          return false;
        }
      } else {
        if (
          save_data?.collection_name?.trim() !== "" &&
          Object.keys(save_data?.sector)?.length > 0 && // Sector selected
          Object.keys(save_data?.category)?.length > 0 && // Category selected
          save_data?.product_name?.trim() !== "" &&
          Object.keys(save_data?.countries)?.length > 0 && // Country selected
          // save_data?.tax?.mrp?.trim() !== "" &&
          //
          Object.keys(save_data?.inventory_type)?.length > 0 &&
          save_data?.batch_number?.trim() !== "" &&
          save_data?.stock?.trim() !== "" &&
          //
          // String(save_data?.maximium_pricing?.selling_price)?.trim() !== "" &&
          String(save_data?.pricing_without_tax?.selling_price)?.trim() !== ""
        ) {
          return true;
        } else {
          return false;
        }
      }
    }
  }

  async function create_collection() {
    let pass_data = {
      get: {
        brand: id_value.brand_id,
      },
      post: {
        collections: [{ name: save_data.collection_name }],
      },
    };
    let response: any = await api("/brand/create_collections", pass_data);
    console.log("/brand/create_collections response :", response.response);
    if (response.status_code == 200) {
      console.log("");
      let send_data = {
        collection_id: response.response.data[0].id,
        // outlet_channel_id : 1,
        // sector_id : save_data.sector.id,
        // category_id : save_data.category.id,
        // name : save_data.product_name,
        // description : save_data.product_description,
        // specifications : [{"id": "115",
        //   "value": [save_data.diet_type]
        // }],
        // images : save_data.selected_files,
        // country_id : save_data.countries.id,
        // state_id : save_data.states.id,
        // mrp : save_data.tax.mrp,
        // gst : save_data.tax.igst,
        // price_without_gst : save_data.pricing_without_tax.total,
        // price_with_gst : save_data.pricing_with_tax.total,
        // manufacturing_date : save_data.manufature_date,
        // expiry_date : save_data.expiry_date
      };
      save_product(send_data, save_data);
    }
  }

  async function save_product(coll_id: any, get_data: any) {
    let pass_data: any = {
      get: {
        brand: id_value.brand_id,
      },
      post: {
        collection_id: coll_id.collection_id,
        // outlet_channel_id: get_data.outlet_channel_id,
        outlet_channel_id: selected_channel.id,
        sector_id: get_data.sector.id,
        category_id: get_data.category.id,
        name: get_data.product_name,
        description: get_data.product_description,
        specifications: [{ id: "115", value: [get_data.diet_type.id] }],
        // images : get_data.selected_files,
        country_id: get_data.countries.id,
        // state_id: get_data.states.id,
        pc_type: get_data.pc_type,
        pc_gst: get_data.pc_gst,
        pc_amount: get_data.pc_amount,
        price_type: get_data.price_type.id,
        price: {
          price_without_tax: get_data.pricing_without_tax.selling_price,
          tax_percent: get_data.tax.igst,
          tax_amount: get_data.maximium_pricing.igst,
          price_with_tax: get_data.pricing_with_tax.selling_price,
          currency_id: 1,
          strike_price_without_tax: get_data.strike_price_without_tax,
          strike_price_tax_amount: get_data.maximium_pricing.igst,
          strike_price_with_tax: get_data.strike_price_with_tax,
        },
        // inventory : {
        //   inventory_type : 2,
        //   batch_number : get_data.batch_number,
        //   manufacturing_date : get_data.manufature_date,
        //   expiry_date : get_data.expiry_date,
        // }
        // mrp: get_data.tax.mrp,
        // gst: get_data.tax.igst,
        // price_without_gst: get_data.pricing_without_tax.selling_price,
        // price_with_gst: get_data.pricing_with_tax.selling_price,
        // manufacturing_date: get_data.manufature_date,
        // expiry_date: get_data.expiry_date,
      },
    };

    if (get_data.price_type.id == 2) {
      pass_data.post.stock = get_data.stock;
    }

    if (get_data.price_type.id == 3) {
      pass_data.post.inventory = {
        inventory_type: 2,
        batch_number: get_data.batch_number,
        manufacturing_date: get_data.manufature_date,
        expiry_date: get_data.expiry_date,
      };
    }

    if (get_data.states.hasOwnProperty("id")) {
      pass_data.post.state_id = get_data.states.id;
    }
    let img_data: any = [];
    get_data.selected_files.map((ele: any) => {
      // img_data.push(ele.file);
      img_data.push(ele.id);
    });
    pass_data.post.images = img_data;
    // pass_data.post.images = get_data.selected_files[0].file;

    // save_data.brand_image[0].file

    console.log("/brand/quick_create_product response pass_data:", pass_data);
    let response: any = await api("/brand/quick_create_product_v2", pass_data);
    console.log("/brand/quick_create_product response :", response.response);
    if (response.status_code == 200) {
      console.log("");
      SetSaveData(initial_value);
      SetPCBottomSheetData((prevalue: any) => {
        prevalue.is_open = false;
        prevalue.data = {};
        return { ...prevalue };
      });
      scrollToTop();
      SetSelectedOutlet((prevalue: any) => {
        let send_data = {
          outlet_value: prevalue,
        };
        get_collections(send_data);
        return { ...prevalue };
      });
      // get_collections();
    }
  }

  async function update_package_charge(get_data: any) {
    console.log("update_package_charge get_data :", get_data);
    let pass_data: any = {
      get: {
        brand: id_value.brand_id,
      },
      post: {
        outlet_id: selected_outlet.id,
        charges: [],
      },
    };
    let push_data: any = {};
    if (get_data.product_type == "product") {
      push_data = {
        product_id: get_data.id,
        type: get_data.type,
        gst: get_data.gst,
        amount: get_data.amount,
      };
      pass_data.post.charges.push(push_data);
    } else {
      push_data = {
        group_id: get_data.id,
        type: get_data.type,
        gst: get_data.gst,
        amount: get_data.amount,
      };
      pass_data.post.charges.push(push_data);
    }
    console.log(
      "/brand/save_product_packaging_charges response pass_data:",
      pass_data
    );
    let response: any = await api(
      "/brand/save_product_packaging_charges",
      pass_data
    );
    console.log(
      "/brand/save_product_packaging_charges response :",
      response.response
    );
    if (response.status_code == 200) {
      console.log("");
      SetSaveData(initial_value);
      SetPCEditBottomSheetData((prevalue: any) => {
        prevalue.is_open = false;
        prevalue.data = {};
        prevalue.all_data = {};
        return { ...prevalue };
      });
      scrollToTop();
      SetSelectedOutlet((prevalue: any) => {
        let send_data = {
          outlet_value: prevalue,
        };
        get_collections(send_data);
        return { ...prevalue };
      });
      // get_collections();
    }
  }

  async function get_collection_product(
    key: any,
    get_data: any,
    have_more_data: any,
    outlet_value: any
  ) {
    console.log("get_collection_product key : ", key, get_data, have_more_data);
    let pass_data: any = {
      get: {
        brand: id_value.brand_id,
        outlet_id: outlet_value.id,
        channel_id: outlet_value.channels[0].id,
      },
    };

    if (id_value.hasOwnProperty("outlet_id")) {
      pass_data.get.outlet = outlet_value.id;
    }

    // SetSelectedOutlet((prevalue :any)=>{
    //   pass_data.get.outlet_id = prevalue.id;
    //   pass_data.get.channel_id = prevalue.channels[0].id;
    //   return {...prevalue}
    // })
    if (key.collection_name == "More") {
      pass_data.get.page = have_more_page_no;
    } else {
      pass_data.get.collection_id = get_data.collection_id;
    }
    console.log("get_collection_product pass_data :", pass_data);
    let response: any = await api(
      "/brand/get_collection_products_v2",
      pass_data
    );
    console.log(
      "/brand/get_collection_product_v2 response :",
      response.response
    );
    if (response.status_code == 200) {
      if (key.collection_name != "More") {
        SetMainData((prevalue: any) => {
          prevalue.map((ele: any) => {
            if (
              ele.collection_id == get_data.collection_id &&
              ele.collection_name == key.collection_name
            ) {
              ele.is_viewed = true;
              ele.show_skeleton = false;
              ele.products = response.response.data;
              ele.collection_is_checked = ele.products.some(
                (pro: any) => pro.channel_switch === "on"
              );
            }
          });
          return [...prevalue];
        });
      } else {
        if (response.response.data.length > 0) {
          SetMainData((prevalue: any) => {
            prevalue.map((ele: any) => {
              if (
                ele.collection_id == get_data.collection_id &&
                ele.collection_name == key.collection_name
              ) {
                ele.is_viewed = true;
                ele.show_skeleton = false;
                response.response.data.map((r_ele: any) => {
                  ele.products.push(r_ele);
                });
              }
            });
            return [...prevalue];
          });
          SetHaveMorePageNo((page_prevalule) => {
            page_prevalule = page_prevalule + 1;
            return page_prevalule;
          });
        } else {
          SetMainData((prevalue: any) => {
            prevalue.map((ele: any) => {
              if (
                ele.collection_id == get_data.collection_id &&
                ele.collection_name == key.collection_name
              ) {
                ele.is_viewed = true;
                ele.show_skeleton = false;
              }
            });
            return [...prevalue];
          });
          SetMoreHasMore(false);
        }
      }

      SetImgBaseUrl(response.response.image_base_url);
    }
  }

  function handleOnView(ele: any, isVisible: any) {
    console.log("handleOnView :", ele, isVisible);
    if (isVisible) {
      SetMainData((prevalue: any) => {
        prevalue.map((m_ele: any, m_index: any) => {
          if (
            m_ele.collection_name == ele.collection_name &&
            m_ele.collection_id == ele.collection_id
          ) {
            if (m_ele.is_viewed == false) {
              let have_more_data: any = {};
              if (ele.collection_name == "More") {
                SetHaveMorePageNo((page_prevale) => {
                  have_more_data.page_no = page_prevale;
                  return page_prevale;
                });
              }
              SetSelectedOutlet((prevalue: any) => {
                get_collection_product(ele, m_ele, have_more_data, prevalue);
                return { ...prevalue };
              });
              // get_collection_product(ele, m_ele, have_more_data);
            }
          }
        });
        return [...prevalue];
      });
    }
  }

  function on_available_change(event: any, p_ele: any, p_index: any, ele: any) {
    console.log("on available change event :", event, p_ele, p_index, ele);
    console.log("event.target.checked :", event.target.checked);
    if (event.target.checked) {
      if (
        p_ele?.channel_force_available?.value == 2 &&
        p_ele?.channel_unavailable?.next_slot &&
        p_ele.channel_timing_current == false
      ) {
        console.log("open bottom sheet");
        let send_data1 = {
          p_ele: p_ele,
          p_index: p_index,
          key: ele,
          from: "switch_on",
        };
        SetBottomSheetData((prevalue: any) => {
          prevalue.is_open = true;
          prevalue.data = send_data1;
          return { ...prevalue };
        });
      } else {
        console.log("call api");
        if (p_ele.type == "product") {
          let push_value: any = {
            outlet_channel_product_variants_available: {
              [p_ele.save_id]: {
                value: 1,
              },
            },
          };
          console.log("push_value :", push_value);
          available_submit(push_value, p_ele.type, p_ele, p_index, ele);
        }
        if (p_ele.type == "product_group") {
          let push_value: any = {
            outlet_channel_products_available: {
              [p_ele.save_id]: {
                value: 1,
              },
            },
            outlet_channel_variants_available: {},
          };
          console.log("push_value :", push_value);
          available_submit(push_value, p_ele.type, p_ele, p_index, ele);
        }
      }
    } else {
      if (
        p_ele.channel_unavailable.hasOwnProperty("next_slot") &&
        p_ele.channel_unavailable.next_slot != ""
      ) {
        console.log("open bottom sheet");
        let send_data1 = {
          p_ele: p_ele,
          p_index: p_index,
          key: ele,
          from: "switch_off",
        };
        SetBottomSheetData((prevalue: any) => {
          prevalue.is_open = true;
          prevalue.data = send_data1;
          return { ...prevalue };
        });
      } else {
        console.log("call api");
        if (p_ele.type == "product") {
          let push_value: any = {
            outlet_channel_product_variants_available: {
              [p_ele.save_id]: {
                value: 2,
              },
            },
          };
          console.log("push_value :", push_value);
          available_submit(push_value, p_ele.type, p_ele, p_index, ele);
        }
        if (p_ele.type == "product_group") {
          let push_value: any = {
            outlet_channel_products_available: {
              [p_ele.save_id]: {
                value: 2,
              },
            },
            outlet_channel_variants_available: {},
          };
          console.log("push_value :", push_value);
          available_submit(push_value, p_ele.type, p_ele, p_index, ele);
        }
      }
    }
  }

  async function available_submit(
    get_data: any,
    type: any,
    p_ele: any,
    p_index: any,
    ele: any
  ) {
    // SetSelectedOutlet((sel_outlet:any)=>{
    // selected_outlet
    let pass_data: any = {
      get: {
        brand: id_value.brand_id,

        outlet_id: selected_outlet.id,
        channel_id: selected_channel.id,

        // outlet_id: sel_outlet.id,
        // channel_id: sel_outlet.channels[0].id,
        // outlet_id: id_value?.outlet_id,
        collection_page: 1,
        // bp_ids: [],
      },
      // post: {
      //   data: {
      //     outlet_channel_product_variants_available: get_data,
      //   },
      // },
      post: {},
    };
    if (id_value.hasOwnProperty("outlet_id")) {
      pass_data.get.outlet = id_value?.outlet_id;
    }
    let bp_ids_value = [];
    if (type == "product") {
      pass_data.post.data = get_data;
      bp_ids_value.push(p_ele.brand_product_id);
      pass_data.get.bp_ids = encodeURIComponent(JSON.stringify(bp_ids_value));
    }
    if (type == "product_group") {
      pass_data.post.data = get_data;
      bp_ids_value.push(p_ele.brand_product_group_id);
      pass_data.get.bpg_ids = encodeURIComponent(JSON.stringify(bp_ids_value));
    }
    if (id_value.hasOwnProperty("outlet_id")) {
      pass_data.get.outlet = id_value?.outlet_id;
    }
    console.log("available_submit pass_data :", pass_data);
    // let response: any = await api(
    //   "/brand/save_outlet_product_variants_v2",
    //   pass_data,
    //   false,
    //   false,
    //   "raw_data"
    // );
    let response: any;
    if (type == "product") {
      response = await api(
        "/brand/save_outlet_product_variants_v2",
        pass_data,
        false,
        false,
        "raw_data"
      );
    }

    if (type == "product_group") {
      response = await api(
        "/brand/save_outlet_channel_products_v2",
        pass_data,
        false,
        false,
        "raw_data"
      );
    }

    if (response.status_code == 200) {
      toast.success("Update Successful");
      // SetMainData((prevalue: any) => {
      //   prevalue[key].products[p_index] = response.response.data[0];
      //   return { ...prevalue };
      // });
      SetMainData((prevalue: any) => {
        prevalue.map((m_ele: any, m_index: any) => {
          if (
            m_ele.collection_name == ele.collection_name &&
            m_ele.collection_id == ele.collection_id
          ) {
            m_ele.products[p_index] = response.response.data[0];
          }
        });
        return [...prevalue];
      });
    } else {
      toast.error("Somthing went ");
    }
    SetBottomSheetData((prevalue: any) => {
      prevalue.is_open = false;
      prevalue.data = {};
      return { ...prevalue };
    });
    SetUncheckRadioValue({
      checked_value: "",
      other_value: "",
    });
    //   return {...sel_outlet}
    // })
  }

  function have_force_available(ele: any) {
    let return_data;
    return_data = ele.products.some(
      (pro: any) => pro.channel_force_available?.value == 2
    );
    return return_data;
  }

  function have_next_slot(ele: any) {
    let return_data;
    return_data = ele.products.some(
      (pro: any) => pro.channel_unavailable?.next_slot
    );
    return return_data;
  }

  function have_timing_current(ele: any) {
    let return_data;
    return_data = ele.products.some(
      (pro: any) => pro.channel_timing_current == false
    );
    return return_data;
  }

  function on_collection_available_change(event: any, ele: any, index: any) {
    console.log("on_collection_available change event :", event, ele);
    console.log("event.target.checked :", event.target.checked);
    let send_data: any = {
      index: index,
    };
    if (ele.collection_id != 0) {
      send_data.collection_id = ele.collection_id;
    }
    if (event.target.checked) {
      // send_data.status = { value: 1 };
      // collection_available_submit(send_data,event);
      if (
        have_force_available(ele) &&
        have_next_slot(ele) &&
        have_timing_current(ele)
      ) {
        console.log("open bottom sheet");
        let send_data1: any = {
          p_ele: ele,
          p_index: index,
          key: ele,
          from: "switch_on",
          event: event,
        };

        if (ele.collection_id != 0) {
          send_data1.collection_id = ele.collection_id;
        }
        SetCollectionBottomSheetData((prevalue: any) => {
          prevalue.is_open = true;
          prevalue.data = send_data1;
          return { ...prevalue };
        });
      } else {
        send_data.from = "switch_on";
        send_data.status = { value: 1 };
        collection_available_submit(send_data, event);
      }
    } else {
      console.log("changing to OFF");
      console.log("changing to OFF have_next_slot(ele) :", have_next_slot(ele));
      console.log("changing to OFF ele :", ele);
      // send_data.status = { value: 2 };
      // collection_available_submit(send_data,event);
      if (have_next_slot(ele)) {
        console.log("open bottom sheet");
        let send_data1: any = {
          p_ele: ele,
          p_index: index,
          key: ele,
          from: "switch_off",
          event: event,
        };
        if (ele.collection_id != 0) {
          send_data1.collection_id = ele.collection_id;
        }
        SetCollectionBottomSheetData((prevalue: any) => {
          prevalue.is_open = true;
          prevalue.data = send_data1;
          return { ...prevalue };
        });
      } else {
        send_data.from = "switch_off";
        send_data.status = { value: 2 };
        collection_available_submit(send_data, event);
      }
    }
  }

  async function collection_available_submit(get_data: any, event: any) {
    // SetSelectedOutlet((sel_outlet:any)=>{
    let pass_data: any = {
      get: {
        brand: id_value.brand_id,
        outlet_id: selected_outlet.id,
        // outlet_id: sel_outlet.id,
        collection_page: 1,
      },
      post: {
        channel_id: selected_channel.id,
        // channel_id: sel_outlet.channels[0].id,
        status: get_data.status,
      },
    };
    if (id_value.hasOwnProperty("outlet_id")) {
      pass_data.get.outlet = id_value?.outlet_id;
    }
    if (get_data.hasOwnProperty("collection_id")) {
      pass_data.post.collection_id = get_data.collection_id;
    }
    let response: any = await api(
      "/brand/save_product_variants_collection_available",
      pass_data,
      false,
      false
    );
    console.log("response :", response);
    if (response.status_code == 200) {
      toast.success("Update Successful");
      console.log("event.target.checked :", event.target.checked);
      SetMainData((prevalue: any) => {
        if (event.target.checked) {
          prevalue[get_data.index].collection_is_checked = false;
        } else {
          prevalue[get_data.index].collection_is_checked = true;
        }
        let have_more_data = {};
        SetSelectedOutlet((s_prevalue: any) => {
          get_collection_product(
            prevalue[get_data.index],
            prevalue[get_data.index],
            have_more_data,
            s_prevalue
          );
          return { ...s_prevalue };
        });

        // get_collection_product(
        //   prevalue[get_data.index],
        //   prevalue[get_data.index],
        //   have_more_data
        // );
        console.log("prevalue :", prevalue);
        return [...prevalue];
      });

      // SetMainData((prevalue: any) => {
      //   prevalue.map((m_ele: any, m_index: any) => {
      //     if (
      //       m_ele.collection_name == ele.collection_name &&
      //       m_ele.collection_id == ele.collection_id
      //     ) {
      //       m_ele.products[p_index] = response.response.data[0];
      //     }
      //   });
      //   return [...prevalue];
      // });
      SetCollectionBottomSheetData((prevalue: any) => {
        prevalue.is_open = false;
        prevalue.data = {};
        return { ...prevalue };
      });
      SetCollectionUncheckRadioValue({
        checked_value: "",
        other_value: "",
      });
    } else {
      toast.error("Somthing went ");
    }
    //   return {...sel_outlet}
    // })
  }

  function menuClose() {
    SetOpenCollectionMenu(null);
  }

  const handleWheel = (event: any) => {
    event.preventDefault(); // Prevent scroll from changing the value
  };

  function calculate_price(get_data: any) {
    SetSaveData((prevalue: any) => {
      if (get_data.from == "mrp") {
        let value = get_data.value;
        prevalue.tax.mrp = value;

        // gst_calculate
        let value_123 = 100 / (100 + Number(prevalue.tax.igst));
        let value_456 = value * value_123;
        let gst_amount: any = value - value_456;

        if (prevalue.tax.mrp > 0) {
          prevalue.maximium_pricing.igst = gst_amount.toFixed(2);
          prevalue.pricing_without_tax.selling_price = Number(
            Number(prevalue.tax.mrp) - Number(gst_amount)
          ).toFixed(2);
          prevalue.pricing_with_tax.selling_price = Number(
            prevalue.tax.mrp
          ).toFixed(2);
        }
      }

      if (get_data.from == "gst") {
        let value = get_data.value;
        prevalue.tax.igst = value;
        let divided_gst_value: any = parseFloat(value / 2);
        prevalue.tax.sgst = divided_gst_value.toFixed(2);
        prevalue.tax.cgst = divided_gst_value.toFixed(2);

        // gst_calculate
        let value_123 = 100 / (100 + Number(prevalue.tax.igst));
        let value_456 = prevalue.tax.mrp * value_123;
        let gst_amount: any = prevalue.tax.mrp - value_456;

        prevalue.maximium_pricing.igst = gst_amount.toFixed(2);
        if (prevalue.tax.mrp > 0) {
          prevalue.pricing_without_tax.selling_price = Number(
            parseFloat(prevalue.tax.mrp) - parseFloat(gst_amount)
          ).toFixed(2);
          prevalue.pricing_with_tax.selling_price = Number(
            prevalue.tax.mrp
          ).toFixed(2);
        } else {
          if (prevalue.pricing_with_tax.selling_price > 0) {
            let value_123 = 100 / (100 + Number(prevalue.tax.igst));
            let value_456 = prevalue.pricing_with_tax.selling_price * value_123;
            let new_gst_amount: any =
              prevalue.pricing_with_tax.selling_price - value_456;

            prevalue.pricing_without_tax.selling_price = Number(
              parseFloat(prevalue.pricing_with_tax.selling_price) -
                parseFloat(new_gst_amount)
            ).toFixed(2);
          }
        }
      }

      if (get_data.from == "price_without_tax") {
        let value = get_data.value;
        prevalue.pricing_without_tax.selling_price = parseFloat(value);

        // const gstAmount: any = (value * prevalue.tax.igst) / 100;

        if (prevalue.tax.mrp > 0) {
          let value_123 = 100 / (100 + Number(prevalue.tax.igst));
          let value_456 = prevalue.tax.mrp * value_123;
          let gst_amount: any = prevalue.tax.mrp - value_456;
          let with_tax_amout: any = parseFloat(value) + parseFloat(gst_amount);

          if (parseFloat(with_tax_amout) <= parseFloat(prevalue.tax.mrp)) {
            prevalue.pricing_with_tax.selling_price =
              parseFloat(with_tax_amout).toFixed(2);
          } else {
            toast.error("Selling Price is higher than MRP");
            prevalue.pricing_with_tax.selling_price = "";
            prevalue.pricing_without_tax.selling_price = "";
          }
        } else {
          let value_123 = 100 / (100 + Number(prevalue.tax.igst));
          let value_456 = value * value_123;
          let gst_amount: any = value - value_456;
          let with_tax_amout: any = parseFloat(value) + parseFloat(gst_amount);

          prevalue.pricing_with_tax.selling_price =
            parseFloat(with_tax_amout).toFixed(2);
        }
        prevalue.strike_price_without_tax = 0;
        prevalue.strike_price_with_tax = 0;
      }

      if (get_data.from == "price_with_tax") {
        let value = get_data.value;

        prevalue.pricing_with_tax.selling_price = parseFloat(value);

        // gst_calculate
        let value_123 = 100 / (100 + Number(prevalue.tax.igst));
        let value_456 = value * value_123;
        let gst_amount: any = value - value_456;

        let return_value: any = parseFloat(value) - parseFloat(gst_amount);

        if (prevalue.tax.mrp > 0) {
          if (parseFloat(value) <= parseFloat(prevalue.tax.mrp)) {
            prevalue.pricing_without_tax.selling_price =
              parseFloat(return_value).toFixed(2);
          } else {
            toast.error("Selling Price is higher than MRP");
            prevalue.pricing_with_tax.selling_price = "";
            prevalue.pricing_without_tax.selling_price = "";
          }
        } else {
          prevalue.pricing_without_tax.selling_price =
            parseFloat(return_value).toFixed(2);
        }

        prevalue.strike_price_without_tax = 0;
        prevalue.strike_price_with_tax = 0;
      }

      if (get_data.from == "strike_without_tax") {
        let value = get_data.value;
        prevalue.strike_price_without_tax = parseFloat(value);

        // const gstAmount = (value * prevalue.tax.igst) / 100;

        if (prevalue.tax.mrp > 0) {
          let value_123 = 100 / (100 + Number(prevalue.tax.igst));
          let value_456 = prevalue.tax.mrp * value_123;
          let gst_amount: any = prevalue.tax.mrp - value_456;

          let with_tax_amout: any = parseFloat(value) + parseFloat(gst_amount);

          if (prevalue.pricing_without_tax.selling_price > 0) {
            if (prevalue.pricing_without_tax.selling_price < value) {
              if (parseFloat(with_tax_amout) <= parseFloat(prevalue.tax.mrp)) {
                prevalue.strike_price_without_tax = parseFloat(value);
                prevalue.strike_price_with_tax =
                  parseFloat(with_tax_amout).toFixed(2);
              } else {
                toast.error("Strike Price is higher than MRP");
                prevalue.strike_price_with_tax = "";
                // prevalue.strike_price_without_tax = "";
              }
            } else {
              toast.error("Strike Price is less than Selling price");
              prevalue.strike_price_with_tax = "";
              // prevalue.strike_price_without_tax = "";
            }
          } else {
            if (parseFloat(with_tax_amout) <= parseFloat(prevalue.tax.mrp)) {
              prevalue.strike_price_without_tax = parseFloat(value);
              prevalue.strike_price_with_tax =
                parseFloat(with_tax_amout).toFixed(2);
            } else {
              toast.error("Strike Price is higher than MRP");
              prevalue.strike_price_with_tax = "";
              // prevalue.strike_price_without_tax = "";
            }
          }
        } else {
          let value_123 = 100 / (100 + Number(prevalue.tax.igst));
          let value_456 = value * value_123;
          let gst_amount: any = value - value_456;

          let with_tax_amout: any = parseFloat(value) + parseFloat(gst_amount);

          if (prevalue.pricing_without_tax.selling_price > 0) {
            if (prevalue.pricing_without_tax.selling_price < value) {
              prevalue.strike_price_without_tax = parseFloat(value);
              prevalue.strike_price_with_tax =
                parseFloat(with_tax_amout).toFixed(2);
            } else {
              toast.error("Strike Price is less than Selling price");
              prevalue.strike_price_with_tax = "";
              // prevalue.strike_price_without_tax = "";
            }
          } else {
            prevalue.strike_price_without_tax = parseFloat(value);
            prevalue.strike_price_with_tax =
              parseFloat(with_tax_amout).toFixed(2);
          }
        }
      }

      if (get_data.from == "strike_with_tax") {
        let value = get_data.value;
        prevalue.strike_price_with_tax = parseFloat(value);

        // gst_calculate
        let value_123 = 100 / (100 + Number(prevalue.tax.igst));
        let value_456 = value * value_123;
        let gst_amount: any = value - value_456;
        let return_value: any = parseFloat(value) - parseFloat(gst_amount);

        if (prevalue.tax.mrp > 0) {
          if (prevalue.pricing_with_tax.selling_price > 0) {
            if (prevalue.pricing_with_tax.selling_price < value) {
              if (parseFloat(value) <= parseFloat(prevalue.tax.mrp)) {
                prevalue.strike_price_without_tax =
                  parseFloat(return_value).toFixed(2);
              } else {
                toast.error("Strike Price is higher than MRP");
                // prevalue.strike_price_with_tax = "";
                prevalue.strike_price_without_tax = "";
              }
            } else {
              toast.error("Strike price must be higher than selling price");
              // prevalue.strike_price_with_tax = "";
              // prevalue.strike_price_without_tax = "";
            }
          } else {
            if (parseFloat(value) <= parseFloat(prevalue.tax.mrp)) {
              prevalue.strike_price_without_tax =
                parseFloat(return_value).toFixed(2);
            } else {
              toast.error("Strike Price is higher than MRP");
              // prevalue.strike_price_with_tax = "";
              prevalue.strike_price_without_tax = "";
            }
          }
        } else {
          if (prevalue.pricing_with_tax.selling_price > 0) {
            if (prevalue.pricing_with_tax.selling_price < value) {
              prevalue.strike_price_with_tax = parseFloat(value);
              prevalue.strike_price_without_tax =
                parseFloat(return_value).toFixed(2);
            } else {
              toast.error("Strike Price is less than Selling price");
              // prevalue.strike_price_with_tax = "";
              prevalue.strike_price_without_tax = "";
            }
          } else {
            prevalue.strike_price_with_tax = parseFloat(value);
            prevalue.strike_price_without_tax =
              parseFloat(return_value).toFixed(2);
          }
        }
      }

      console.log("prevalue :", prevalue);

      return { ...prevalue };
    });
  }

  function getPriceTypeValue(getData: any) {
    let return_data: any;
    price_type_options.map((ele: any) => {
      if (ele.id == getData) {
        return_data = ele;
      }
    });
    return return_data;
  }

  function getDetailsValue(getData: any) {
    let return_data : any;

    if(getData.from == "pwt"){
      if(getData.data.price_type == 1){
        
      }
    }
    return return_data
  }

  return (
    <div className="w-100">
      {/* header */}
      <div
        className={OutletContext ? "head-bar sticky-sm py-3" : "head-bar py-3"}
        style={OutletContext == false ? { width: width } : {}}
      >
        <div className="row align-items-center">
          <div className="col-md-5 ps-0">
            <h6 className="fs-5  mb-0">Manage Products</h6>
          </div>
          {/* outlet / channel */}
          <div className=" col-md-7 px-0 pt-sm-2 pt-md-0">
            <div className="row">
              <div className="col-6 pe-1 ps-0 ">
                <div className="select-wrap">
                  <label className="item-center">Outlet</label>
                  <select
                    data-show-content="true"
                    className="form-control"
                    id="exampleFormControlSelect1"
                    value={selected_outlet.id}
                    onChange={(e) => {
                      console.log("on change :", e.target.value);
                      let value_data = e.target.value;
                      SetOutletList((prevalue: any) => {
                        prevalue.map((ele: any, index: any) => {
                          if (ele.id == value_data) {
                            SetSelectedOutlet(ele);
                            ele.channels.map((c_ele: any, c_index: any) => {
                              if (c_index == 0) {
                                SetSelectedChannel(c_ele);
                              }
                            });
                            SetChannelList(ele.channels);
                          }
                        });
                        return [...prevalue];
                      });
                      scrollToTop();
                      SetSelectedOutlet((prevalue: any) => {
                        let send_data = {
                          outlet_value: prevalue,
                        };
                        get_collections(send_data);
                        return { ...prevalue };
                      });
                      // get_collections();
                    }}
                  >
                    {outlet_list?.map((ele: any, i: any) => (
                      <option key={i} value={ele?.id}>
                        {ele?.name}
                      </option>
                    ))}
                  </select>
                </div>
              </div>

              <div className="col-6 ps-1 pe-0">
                <div className="select-wrap">
                  <label className="item-center">Channel</label>
                  <select
                    data-show-content="true"
                    className="form-control"
                    id="exampleFormControlSelect1"
                    value={selected_channel.id}
                    onChange={(e) => {
                      console.log("on change :", e.target.value);
                      let value_data = e.target.value;
                      SetChannelList((prevalue: any) => {
                        prevalue.map((ele: any) => {
                          if (ele.id == value_data) {
                            SetSelectedChannel(ele);
                          }
                        });
                        return [...prevalue];
                      });
                      scrollToTop();
                      SetSelectedOutlet((prevalue: any) => {
                        let send_data = {
                          outlet_value: prevalue,
                        };
                        get_collections(send_data);
                        return { ...prevalue };
                      });
                      // get_collections();
                    }}
                  >
                    {channel_list?.map((ele: any, i: any) => (
                      <option key={i} value={ele?.id}>
                        {ele?.name}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="col-md-6">
        {is_loading ? (
          <div className="card px-2 py-1 mb-3">
            {[1, 2, 3, 4, 5, 6].map((sk_ele) => (
              <div className="mt-3 col-12">
                <Skeleton width={"100%"} height={80} />
              </div>
            ))}
          </div>
        ) : main_data.length > 0 ? (
          <div className="px-sm-1 px-md-0 mt-3 mb-5 prod_collection">
            {!id_value.hasOwnProperty("outlet_id") && (
              <div className="pb-2">
                <div
                  className="mt-3 mb-3 center"
                  onClick={() => {
                    SetPCBottomSheetData((prevalue: any) => {
                      prevalue.is_open = true;
                      prevalue.data = {
                        c_from: "new",
                        key: 0,
                        index: 0,
                        collection_id: 0,
                        main_data_value: main_data,
                      };
                      return { ...prevalue };
                    });
                  }}
                >
                  <div className="btn btn-bg-fff-text-prime">
                    Add Collection and Product
                  </div>
                </div>
              </div>
            )}

            {/* prod_collection */}
            {main_data?.map((ele: any, index: any) => (
              <div className="row md-ps-0 sm-px-3" key={index}>
                <VisibilitySensor
                  partialVisibility={true}
                  active={visibility_sensor_active}
                  onChange={(isVisible: any) => {
                    if (isVisible) {
                      handleOnView(ele, isVisible);
                    }
                  }}
                >
                  <div className="card px-2 py-1 mb-3" id={ele.collection_id}>
                    <Accordion className="px-0" defaultExpanded={true}>
                      <AccordionSummary
                        // expandIcon={
                        //   <img
                        //     src={
                        //       AppConfig.CDN_Media_url +
                        //       "43fd67bad621bf93fac936e25b16c7aa.svg"
                        //     }
                        //     className="w-10px"
                        //   />
                        // }
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                        className="px-1"
                      >
                        <div className="d-flex w-100 center">
                          <div className="d-flex">
                            <h5 className="text-black mb-0 fw-bold">
                              {ele.collection_name}
                            </h5>
                            <img
                              src={
                                AppConfig.CDN_Media_url +
                                "43fd67bad621bf93fac936e25b16c7aa.svg"
                              }
                              className="ps-2 w-25px ms-auto"
                            />
                          </div>
                          <div className="ms-auto">
                            <label className="pure-material-switch mb-0 mt-1 ms-auto">
                              <input
                                type="checkbox"
                                id={"avail" + index}
                                className="matching-group-switch"
                                checked={ele.collection_is_checked}
                                onChange={(event) => {
                                  console.log("collection switch on change");
                                  on_collection_available_change(
                                    event,
                                    ele,
                                    index
                                  );
                                }}
                              />
                              <span></span>
                            </label>
                          </div>
                        </div>
                      </AccordionSummary>
                      <div className="row align-items-center border-bottom"></div>
                      <AccordionDetails className="">
                        <div className="w-100">
                          {/* <ProductCard /> */}
                          {ele.show_skeleton ? (
                            [1, 2, 3, 4, 5, 6].map((sk_ele) => (
                              <div className="mt-3 col-12">
                                <Skeleton width={"100%"} height={80} />
                              </div>
                            ))
                          ) : (
                            <>
                              {!id_value.hasOwnProperty("outlet_id") && (
                                <div
                                  className="mt-2 mb-2 center"
                                  onClick={() => {
                                    SetPCBottomSheetData((prevalue: any) => {
                                      prevalue.is_open = true;
                                      prevalue.data = {
                                        c_from: "product",
                                        key: index,
                                        index: index,
                                        collection_id: ele.collection_id,
                                        main_data_value: main_data,
                                      };
                                      SetSaveData(initial_value);
                                      return { ...prevalue };
                                    });
                                  }}
                                >
                                  <div className="btn cancel-order-btn-prime">
                                    Add Product in Collection
                                  </div>
                                </div>
                              )}

                              {ele.products.length > 0 ? (
                                <>
                                  {ele.collection_name != "More" ? (
                                    ele.products.map(
                                      (p_ele: any, p_index: any) => (
                                        <div className="w-100  pt-3 pb-2 border-bottom">
                                          <ProductCard
                                            data={p_ele}
                                            p_index={p_index}
                                            index={index}
                                            ele={ele}
                                          />
                                        </div>
                                      )
                                    )
                                  ) : (
                                    <InfiniteScroll
                                      dataLength={ele.products.length}
                                      next={() => {
                                        console.log("calling from next");
                                        let have_more_data: any = {};
                                        SetHaveMorePageNo((page_prevale) => {
                                          have_more_data.page_no = page_prevale;
                                          return page_prevale;
                                        });
                                        SetSelectedOutlet((prevalue: any) => {
                                          get_collection_product(
                                            ele,
                                            ele,
                                            have_more_data,
                                            prevalue
                                          );
                                          return { ...prevalue };
                                        });
                                        // get_collection_product(
                                        //   ele,
                                        //   ele,
                                        //   have_more_data
                                        // );
                                      }}
                                      hasMore={more_has_more}
                                      loader={
                                        <div className="d-flex align-items-center justify-content-center text-center p-5">
                                          <div className="lds-dual-ring"></div>
                                          <span className=" small ps-2">
                                            Just a second ...
                                          </span>
                                        </div>
                                      }
                                      endMessage={
                                        <div className="d-flex align-items-center justify-content-center text-center p-3">
                                          <span className=" small">
                                            --- All Products Loaded ---
                                          </span>
                                        </div>
                                      }
                                    >
                                      {ele.products.map(
                                        (p_ele: any, p_index: any) => (
                                          <div className="w-100  pt-3 pb-2 border-bottom">
                                            <ProductCard
                                              data={p_ele}
                                              p_index={p_index}
                                              index={index}
                                              ele={ele}
                                            />
                                          </div>
                                        )
                                      )}
                                    </InfiniteScroll>
                                  )}
                                </>
                              ) : (
                                <div className="center mt-3">
                                  <p>No Products</p>
                                </div>
                              )}
                            </>
                          )}
                        </div>
                      </AccordionDetails>
                    </Accordion>
                  </div>
                </VisibilitySensor>
              </div>
            ))}
          </div>
        ) : (
          <div
            className="mt-5 mb-2 center"
            onClick={() => {
              SetPCBottomSheetData((prevalue: any) => {
                prevalue.is_open = true;
                prevalue.data = {
                  c_from: "new",
                  key: 0,
                  index: 0,
                  collection_id: 0,
                  main_data_value: main_data,
                };
                SetSaveData(initial_value);
                return { ...prevalue };
              });
            }}
          >
            <div className="btn btn-primary">Create collection and product</div>
          </div>
        )}
      </div>

      {/* product create form */}
      {pc_bottom_sheet_data.is_open ? (
        <div className="out_of_stock_swipe">
          <SwipeableBottomSheet
            overflowHeight={0}
            overlay={true}
            style={{ zIndex: 1300 }}
            open={pc_bottom_sheet_data.is_open}
            onClose={() => {
              SetPCBottomSheetData((prevalue: any) => {
                prevalue.is_open = false;
                prevalue.data = {};
                return { ...prevalue };
              });
              setTimeout(() => {
                SetSaveData(initial_value);
              }, 300);
            }}
            onChange={() => {
              SetPCBottomSheetData((prevalue: any) => {
                prevalue.is_open = false;
                prevalue.data = {};
                return { ...prevalue };
              });
              setTimeout(() => {
                SetSaveData(initial_value);
              }, 300);
            }}
          >
            <div>
              <div className="w-100 mt-2 d-flex bg-fff center sticky-sm">
                <hr className="hr-drawer" />
              </div>
              <div
                className="bg-fff p-2 mb-5"
                key={pc_bottom_sheet_data.data.key}
              >
                <div className="d-flex px-2 center">
                  <h6
                    onClick={() => {
                      console.log("save_data :", save_data);
                    }}
                  >
                    Create Product
                  </h6>
                  <div className="ms-auto">
                    <button
                      type="button"
                      className="btn-close"
                      aria-label="Close"
                      onClick={() => {
                        SetPCBottomSheetData((prevalue: any) => {
                          prevalue.is_open = false;
                          prevalue.data = {};
                          return { ...prevalue };
                        });
                      }}
                    ></button>
                  </div>
                </div>
                {/* collection name */}
                {pc_bottom_sheet_data.data.c_from == "new" && (
                  <div className="bg-fff p-2">
                    <div className="position-relative mt-3 border radius-6">
                      <div className="d-flex w-100">
                        <input
                          type="text"
                          className="outside w-90per border-none bg-none bg-body-color"
                          value={save_data?.collection_name}
                          // value={collection_data}
                          onChange={(e: any) => {
                            SetSaveData((prevalue: any) => {
                              prevalue.collection_name = e.target.value;
                              return { ...prevalue };
                            });
                          }}
                        />
                        <div className="center w-10per cursor"></div>
                        <span className="floating-label-outside bg-fff">
                          Collection *
                        </span>
                      </div>
                    </div>
                  </div>
                )}
                {/* Other Fields */}
                <div className="p-2 bg-fff  pt-3">
                  {/* Sector */}
                  <div className="position-relative mt-1">
                    <BootstrapSelect
                      className="outside"
                      isDropdownOpen={isDropdownOpen}
                      setDropdownOpen={setDropdownOpen}
                      value={save_data?.sector}
                      options={sector_options}
                      placeholder="Select an option..."
                      isMultiSelect={false}
                      optionName="Sector *"
                      callBack={(data: any) => {
                        console.log("on select sector :", data);

                        SetSaveData((prevalue) => {
                          prevalue.sector = data.data;
                          prevalue.category = {};
                          prevalue.countries = {
                            id: 99,
                            iso: "IN",
                            name: "INDIA",
                            nicename: "India",
                            iso3: "IND",
                            numcode: 356,
                            phonecode: 91,
                            value: 99,
                            label: "India",
                          };
                          // prevalue.states = {
                          //   id: 31,
                          //   nicename: "Tamil Nadu",
                          // };
                          const timestamp = new Date()
                            .getTime()
                            .toString()
                            .slice(-6); // Last 6 digits of timestamp
                          const randomPart = Math.random()
                            .toString(36)
                            .substring(2, 6)
                            .toUpperCase(); // 4 random alphanumeric chars
                          // const batch = `BATCH-${timestamp}${randomPart}`;
                          const batch = `${timestamp}${randomPart}`;
                          // prevalue.batch_number = batch;
                          // prevalue.inventory_based = { name: "Yes", id: "1" };
                          // prevalue.inventory_type = { name: "MRP", id: "2" };
                          return { ...prevalue };
                        });
                        SetAllCategoryOptions((all_prevalue) => {
                          console.log("all_prevalue :", all_prevalue);
                          if (data.data.id == 1) {
                            SetCategoryOptions(all_prevalue["1"]);
                            SetShowDiet(true);
                          }
                          if (data.data.id == 2) {
                            SetCategoryOptions(all_prevalue["2"]);
                            SetShowDiet(true);
                          }
                          if (data.data.id == 3) {
                            SetCategoryOptions(all_prevalue["3"]);
                            SetShowDiet(false);
                          }
                          return all_prevalue;
                        });
                      }}
                    />
                  </div>
                  {/* Category */}
                  <div className="position-relative mt-4">
                    <BootstrapSelect
                      className="outside"
                      haveSearch={true}
                      isDropdownOpen={isDropdownOpen}
                      setDropdownOpen={setDropdownOpen}
                      value={save_data?.category}
                      options={category_options}
                      placeholder="Select an option..."
                      isMultiSelect={false}
                      optionName="Category *"
                      callBack={(data: any) => {
                        console.log("on select sector :", data);
                        SetSaveData((prevalue) => {
                          prevalue.category = data.data;
                          return { ...prevalue };
                        });
                      }}
                    />
                  </div>
                  {/* Product Name */}
                  <div className="position-relative mt-4">
                    <input
                      type="text"
                      className="outside"
                      required
                      value={save_data?.product_name}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        SetSaveData((prevalue) => {
                          prevalue.product_name = e.target.value;
                          return { ...prevalue };
                        });
                      }}
                    />
                    <span className="floating-label-outside">
                      Product Name *
                    </span>
                  </div>
                  {/* Product Description */}
                  <div className="position-relative mt-4">
                    <input
                      type="text"
                      className="outside"
                      required
                      value={save_data?.product_description}
                      onChange={(e: any) => {
                        SetSaveData((prevalue) => {
                          prevalue.product_description = e.target.value;
                          return { ...prevalue };
                        });
                      }}
                    />
                    <span className="floating-label-outside">
                      Product Description
                    </span>
                  </div>
                  {/* Diet */}
                  {show_diet && (
                    <div className="position-relative mt-4">
                      <BootstrapSelect
                        className="outside"
                        isDropdownOpen={isDropdownOpen}
                        setDropdownOpen={setDropdownOpen}
                        value={save_data?.diet_type}
                        options={diet_options}
                        isMultiSelect={false}
                        placeholder="Select an option..."
                        optionName="Diet"
                        callBack={(data: any) => {
                          console.log("on select sector :", data);
                          SetSaveData((prevalue) => {
                            prevalue.diet_type = data.data;
                            return { ...prevalue };
                          });
                          // if (c_from == "new") {
                          //   SetSaveData((prevalue) => {
                          //     prevalue.diet_type = data.data;
                          //     return { ...prevalue };
                          //   });
                          // } else {
                          //   SetMainData1((prevalue: any) => {
                          //     prevalue[index].add_product_form.diet_type = data.data;
                          //     return [...prevalue];
                          //   });
                          // }
                        }}
                      />
                    </div>
                  )}

                  {/* Product Image */}
                  <div className="position-relative mt-4 w-100">
                    <div className="outside w-100">
                      <input
                        ref={fileInputRef}
                        className="outside FileInputNone"
                        type="file"
                        multiple
                        onChange={(evt: any) => {
                          // onFilesAdded(evt);
                        }}
                      />
                      <div className="border radius-10 p-3 w-100">
                        <div className="d-flex align-items-center">
                          {save_data?.selected_files?.map(
                            (element: any, index: any) => (
                              <div className="border radius-6 pre-img-size me-2 position-relative">
                                {/* <img
                                  src={element.url}
                                  className="pre-img-size"
                                /> */}
                                <img
                                  src={AppHelper.imageSrc(
                                    image_base_url,
                                    element.url,
                                    {
                                      format: "webp",
                                      width: 320,
                                    }
                                  )}
                                  className="pre-img-size"
                                />
                                <div
                                  className="cursor text-center text-red over-img-close"
                                  onClick={() => {
                                    SetSaveData((prevValue: any) => {
                                      let file_data = prevValue.selected_files;
                                      file_data.splice(index, 1);
                                      prevValue.selected_files = file_data;
                                      return { ...prevValue };
                                    });
                                  }}
                                >
                                  ×
                                </div>
                              </div>
                            )
                          )}

                          <div
                            className="border radius-6 bg-gray pre-img-size center"
                            onClick={() => {
                              // openFileDialog();
                              SetModalGallery((prevalue) => {
                                prevalue.is_open = true;
                                return { ...prevalue };
                              });
                            }}
                          >
                            <img
                              src={
                                AppConfig.CDN_Media_url +
                                "47b9835dc6f13fad0481e35755afecd2.svg"
                              }
                              className="w-20"
                            />
                          </div>
                        </div>
                      </div>

                      <span className="floating-label-outside">
                        Product Image
                      </span>
                    </div>
                  </div>

                  <div className="fs-12px text-dark mt-4">Contanis</div>

                  {/* countries & state */}
                  <div className="align-items-center d-flex w-100">
                    {/* countries */}
                    <div className="position-relative mt-4 me-1 w-50">
                      <BootstrapSelect
                        className="outside"
                        options={country_options}
                        disabled={true}
                        // value={
                        //   c_from == "new"
                        //     ? save_data.countries
                        //     : main_data1?.[index]?.add_product_form.countries
                        // }
                        isDropdownOpen={isDropdownOpen}
                        setDropdownOpen={setDropdownOpen}
                        value={save_data?.countries}
                        placeholder="Select an option..."
                        isMultiSelect={false}
                        optionName="Market"
                        callBack={(data: any) => {
                          console.log("on select sector :", data);
                          SetSaveData((prevalue) => {
                            prevalue.countries = data.data;
                            return { ...prevalue };
                          });
                          // if (c_from == "new") {
                          //   SetSaveData((prevalue) => {
                          //     prevalue.countries = data.data;
                          //     return { ...prevalue };
                          //   });
                          // } else {
                          //   SetMainData1((prevalue: any) => {
                          //     prevalue[index].add_product_form.countries = data.data;
                          //     return [...prevalue];
                          //   });
                          // }
                        }}
                      />
                    </div>
                    {/* states */}
                    <div className="position-relative mt-4 w-50">
                      <BootstrapSelect
                        className="outside"
                        options={state_options}
                        haveSearch={true}
                        // disabled={true}
                        // value={
                        //   c_from == "new"
                        //     ? save_data.states
                        //     : main_data1?.[index]?.add_product_form.states
                        // }
                        value={save_data.states}
                        isDropdownOpen={isDropdownOpen}
                        setDropdownOpen={setDropdownOpen}
                        value={save_data?.states}
                        isMultiSelect={false}
                        placeholder="Select an option..."
                        optionName="Level"
                        callBack={(data: any) => {
                          console.log("on select sector :", data);
                          SetSaveData((prevalue) => {
                            prevalue.states = data.data;
                            return { ...prevalue };
                          });
                          // if (c_from == "new") {
                          //   SetSaveData((prevalue) => {
                          //     prevalue.states = data.data;
                          //     return { ...prevalue };
                          //   });
                          // } else {
                          //   SetMainData1((prevalue: any) => {
                          //     prevalue[index].add_product_form.states = data.data;
                          //     return [...prevalue];
                          //   });
                          // }
                        }}
                      />
                    </div>
                  </div>

                  {/* MRP */}
                  <div className="fs-12px text-dark mt-4">MRP</div>

                  <div className="d-flex align-items-center">
                    {/* MRP */}
                    <div className="position-relative mt-4 me-1">
                      <input
                        ref={(el) => (numInputsRef.current[0] = el)}
                        type="number"
                        className="outside icon-padding"
                        // placeholder="Enter units"
                        value={save_data?.tax?.mrp}
                        onChange={(e: any) => {
                          let send_data = {
                            from: "mrp",
                            value: e.target.value,
                          };
                          calculate_price(send_data);
                          // SetSaveData((prevalue: any) => {
                          //   prevalue.tax.mrp = e.target.value;

                          //   let divided_gst_value: any = parseFloat(
                          //     e.target.value / 2
                          //   );
                          //   prevalue.tax.sgst = divided_gst_value.toFixed(2);
                          //   prevalue.tax.cgst = divided_gst_value.toFixed(2);

                          //   if (prevalue.tax.mrp > 0) {
                          //     const gstAmount =
                          //       (prevalue.tax.mrp * prevalue.tax.igst) / 100;
                          //     const cgst = gstAmount / 2;
                          //     const sgst = gstAmount / 2;
                          //     const igst = gstAmount;
                          //     prevalue.maximium_pricing.igst = igst.toFixed(2);
                          //     // prevalue.maximium_pricing.sgst = sgst.toFixed(2);
                          //     // prevalue.maximium_pricing.cgst = cgst.toFixed(2);
                          //     prevalue.maximium_pricing.selling_price = Number(
                          //       Number(prevalue.tax.mrp) - Number(gstAmount)
                          //     ).toFixed(2);

                          //     prevalue.pricing_without_tax.selling_price =
                          //       prevalue.maximium_pricing.selling_price;
                          //     // prevalue.pricing_without_tax.total =
                          //     //   prevalue.maximium_pricing.selling_price;

                          //     const final_price =
                          //       Number(
                          //         prevalue.maximium_pricing.selling_price
                          //       ) + gstAmount;
                          //     if (final_price <= prevalue.tax.mrp) {
                          //       prevalue.pricing_with_tax.selling_price =
                          //         final_price;
                          //       // prevalue.pricing_with_tax.total = final_price;
                          //     } else {
                          //       toast.error("Selling Price is higher than MRP");
                          //       prevalue.pricing_with_tax.selling_price = "";
                          //       prevalue.pricing_with_tax.total = "";
                          //     }
                          //   }
                          //   return { ...prevalue };
                          // });
                        }}
                      />
                      <span className="input-icon-absoulate">₹</span>
                      <span className="floating-label-outside l-7px">
                        MRP *
                      </span>
                    </div>
                    {/* IGST */}
                    <div className="position-relative mt-4">
                      <input
                        ref={(el) => (numInputsRef.current[1] = el)}
                        type="number"
                        className="outside "
                        value={save_data?.tax?.igst}
                        onChange={(e: any) => {
                          let send_data = {
                            from: "gst",
                            value: e.target.value,
                          };
                          calculate_price(send_data);
                          // SetSaveData((prevalue: any) => {
                          //   prevalue.tax.igst = e.target.value;
                          //   // let gst_value = e.target.value /2;
                          //   let divided_gst_value: any = parseFloat(
                          //     e.target.value / 2
                          //   );
                          //   prevalue.tax.sgst = divided_gst_value.toFixed(2);
                          //   prevalue.tax.cgst = divided_gst_value.toFixed(2);
                          //   if (prevalue.tax.mrp > 0) {
                          //     const gstAmount =
                          //       (prevalue.tax.mrp * prevalue.tax.igst) / 100;
                          //     const cgst = gstAmount / 2;
                          //     const sgst = gstAmount / 2;
                          //     const igst = gstAmount;

                          //     prevalue.maximium_pricing.igst = igst.toFixed(2);
                          //     // prevalue.maximium_pricing.sgst = sgst.toFixed(2);
                          //     // prevalue.maximium_pricing.cgst = cgst.toFixed(2);
                          //     prevalue.maximium_pricing.selling_price = Number(
                          //       Number(prevalue.tax.mrp) - Number(gstAmount)
                          //     ).toFixed(2);

                          //     // prevalue.pricing_without_tax.selling_price =
                          //     //   prevalue.maximium_pricing.selling_price;

                          //     let value_123 =
                          //       100 / (100 + parseFloat(e.target.value));
                          //     let value_456 = prevalue.tax.mrp * value_123;
                          //     let gst_amount: any =
                          //       prevalue.tax.mrp - value_456;

                          //     prevalue.pricing_without_tax.selling_price = (
                          //       parseFloat(prevalue.tax.mrp) -
                          //       parseFloat(gst_amount)
                          //     ).toFixed(2);

                          //     // prevalue.pricing_without_tax.total =
                          //     //   prevalue.maximium_pricing.selling_price;

                          //     const final_price =
                          //       Number(
                          //         prevalue.maximium_pricing.selling_price
                          //       ) + gstAmount;
                          //     if (final_price <= prevalue.tax.mrp) {
                          //       prevalue.pricing_with_tax.selling_price =
                          //         final_price;
                          //       // prevalue.pricing_with_tax.total = final_price;
                          //     } else {
                          //       toast.error("Selling Price is higher than MRP");
                          //       prevalue.pricing_with_tax.selling_price = "";
                          //       prevalue.pricing_with_tax.total = "";
                          //     }
                          //   }

                          //   return { ...prevalue };
                          // });
                        }}
                      />
                      <span className="floating-label-outside custom-s l-7px px-0">
                        GST %
                      </span>
                    </div>
                  </div>
                  {/*  */}

                  {/* price type */}
                  <div className="position-relative mt-4">
                    <BootstrapSelect
                      className="outside"
                      isDropdownOpen={isDropdownOpen}
                      setDropdownOpen={setDropdownOpen}
                      value={save_data?.price_type}
                      options={price_type_options}
                      placeholder="Select an option..."
                      isMultiSelect={false}
                      optionName="Price Type *"
                      callBack={(data: any) => {
                        console.log("on select sector :", data);

                        SetSaveData((prevalue) => {
                          prevalue.price_type = data.data;
                          return { ...prevalue };
                        });
                      }}
                    />
                  </div>

                  {/* Maximum Selling Price */}
                  <div className="fs-12px text-dark mt-4">
                    Maximum Selling Price
                  </div>

                  <div className="d-flex align-items-center">
                    {/* without tax */}
                    <div className="position-relative mt-4 me-1">
                      <input
                        ref={(el) => (numInputsRef.current[3] = el)}
                        type="number"
                        className="outside icon-padding"
                        value={save_data?.pricing_without_tax?.selling_price}
                        // onWheel={handleWheel}
                        onChange={(e: any) => {
                          let send_data = {
                            from: "price_without_tax",
                            value: e.target.value,
                          };
                          calculate_price(send_data);
                          // SetSaveData((prevalue: any) => {
                          //   let value = e.target.value;
                          //   // if (prevalue.tax.mrp > 0) {
                          //     const gstAmount =
                          //       (value * prevalue.tax.igst) / 100;
                          //     prevalue.pricing_without_tax.selling_price =
                          //       parseFloat(value);
                          //     let with_tax_amout =
                          //       parseFloat(value) + parseFloat(gstAmount);
                          //     if (
                          //       parseFloat(with_tax_amout) <=
                          //       parseFloat(prevalue.tax.mrp)
                          //     ) {
                          //       // prevalue.pricing_without_tax.selling_price =
                          //       //   Number(value).toFixed(2);
                          //       prevalue.pricing_with_tax.selling_price =
                          //         parseFloat(with_tax_amout).toFixed(2);
                          //     } else {
                          //       toast.error("Selling Price is higher than MRP");
                          //       prevalue.pricing_with_tax.selling_price = "";
                          //       prevalue.pricing_without_tax.selling_price = "";
                          //     }
                          //   // }
                          //   return { ...prevalue };
                          // });
                        }}
                      />
                      <span className="input-icon-absoulate">₹</span>
                      <span className="floating-label-outside l-7px">
                        Price Without Tax
                      </span>
                    </div>

                    {/* with tax */}
                    <div className="position-relative mt-4">
                      <input
                        ref={(el) => (numInputsRef.current[2] = el)}
                        type="number"
                        className="outside icon-padding"
                        value={save_data?.pricing_with_tax?.selling_price}
                        // onWheel={handleWheel}
                        onChange={(e: any) => {
                          let send_data = {
                            from: "price_with_tax",
                            value: e.target.value,
                          };
                          calculate_price(send_data);
                          // SetSaveData((prevalue: any) => {
                          //   let value = e.target.value;
                          //   // if (prevalue.tax.mrp > 0) {
                          //     let value_123 =
                          //       100 / (100 + parseFloat(prevalue.tax.igst));
                          //     let value_456 = value * value_123;
                          //     let gst_amount = value - value_456;
                          //     let return_value =
                          //       parseFloat(value) - parseFloat(gst_amount);
                          //     prevalue.pricing_with_tax.selling_price =
                          //       parseFloat(value);
                          //     if (
                          //       parseFloat(value) <=
                          //       parseFloat(prevalue.tax.mrp)
                          //     ) {
                          //       prevalue.pricing_without_tax.selling_price =
                          //         parseFloat(return_value).toFixed(2);
                          //     } else {
                          //       toast.error("Selling Price is higher than MRP");
                          //       prevalue.pricing_with_tax.selling_price = "";
                          //       prevalue.pricing_without_tax.selling_price = "";
                          //     }
                          //   // }
                          //   return { ...prevalue };
                          // });
                        }}
                      />
                      <span className="input-icon-absoulate">₹</span>
                      <span className="floating-label-outside l-7px">
                        Price With Tax
                      </span>
                    </div>
                  </div>
                  {/*  */}

                  {/* Strike Price */}
                  <div className="fs-12px text-dark mt-4">Strike Price</div>

                  <div className="d-flex align-items-center">
                    {/* without tax */}
                    <div className="position-relative mt-4 me-1">
                      <input
                        ref={(el) => (numInputsRef.current[3] = el)}
                        type="number"
                        className="outside icon-padding"
                        value={save_data?.strike_price_without_tax}
                        // onWheel={handleWheel}
                        onChange={(e: any) => {
                          let send_data = {
                            from: "strike_without_tax",
                            value: e.target.value,
                          };
                          calculate_price(send_data);
                          // SetSaveData((prevalue: any) => {
                          //   let value = e.target.value;

                          //   if (prevalue.tax.mrp > 0) {
                          //     const gstAmount =
                          //       (value * prevalue.tax.igst) / 100;

                          //     prevalue.strike_price_without_tax =
                          //       parseFloat(value);

                          //     let with_tax_amout =
                          //       parseFloat(value) + parseFloat(gstAmount);
                          //     console.log("with_tax_amout :",with_tax_amout);
                          //     console.log("prevalue.tax.mrp :",prevalue.tax.mrp);
                          //     if (
                          //       parseFloat(with_tax_amout) <=
                          //       parseFloat(prevalue.tax.mrp)
                          //     ) {
                          //       prevalue.strike_price_with_tax =
                          //         parseFloat(with_tax_amout).toFixed(2);

                          //     } else {
                          //       toast.error("Strike Price is higher than MRP");
                          //       prevalue.strike_price_with_tax = "";
                          //       prevalue.strike_price_without_tax = "";
                          //     }
                          //   }
                          //   return { ...prevalue };
                          // });
                        }}
                      />
                      <span className="input-icon-absoulate">₹</span>
                      <span className="floating-label-outside l-7px">
                        Without Tax
                      </span>
                    </div>

                    {/* with tax */}
                    <div className="position-relative mt-4">
                      <input
                        ref={(el) => (numInputsRef.current[2] = el)}
                        type="number"
                        className="outside icon-padding"
                        value={save_data?.strike_price_with_tax}
                        // onWheel={handleWheel}
                        onChange={(e: any) => {
                          let send_data = {
                            from: "strike_with_tax",
                            value: e.target.value,
                          };
                          calculate_price(send_data);
                          // SetSaveData((prevalue: any) => {
                          //   let value = e.target.value;

                          //   if (prevalue.tax.mrp > 0) {
                          //     let value_123 =
                          //       100 / (100 + parseFloat(prevalue.tax.igst));
                          //     let value_456 = value * value_123;
                          //     let gst_amount = value - value_456;
                          //     let return_value =
                          //       parseFloat(value) - parseFloat(gst_amount);

                          //     prevalue.strike_price_with_tax =
                          //       parseFloat(value);

                          //     if (
                          //       parseFloat(value) <=
                          //       parseFloat(prevalue.tax.mrp)
                          //     ) {
                          //       prevalue.strike_price_without_tax =
                          //         parseFloat(return_value).toFixed(2);
                          //     } else {
                          //       toast.error("Strike Price is higher than MRP");
                          //       prevalue.strike_price_with_tax = "";
                          //       prevalue.strike_price_without_tax = "";
                          //     }
                          //   }
                          //   return { ...prevalue };
                          // });
                        }}
                      />
                      <span className="input-icon-absoulate">₹</span>
                      <span className="floating-label-outside l-7px">
                        With Tax
                      </span>
                    </div>
                  </div>
                  {/*  */}

                  {/* Stock */}
                  {save_data?.price_type.id == 2 && (
                    <div className="position-relative mt-4">
                      <input
                        type="number"
                        className="outside"
                        required
                        value={save_data?.stock}
                        onChange={(e: any) => {
                          SetSaveData((prevalue) => {
                            prevalue.stock = e.target.value;
                            return { ...prevalue };
                          });
                        }}
                      />
                      <span className="floating-label-outside">Stock</span>
                    </div>
                  )}

                  {/* Inventory Based / Invontry Type / Date / batch number*/}
                  {(save_data?.price_type.id == 3 ||
                    save_data?.price_type.id == 4) && (
                    <>
                      {/* Invontry Type */}
                      <div className="d-flex align-items-center w-100">
                        <div className="position-relative mt-4 me-1 w-50">
                          <BootstrapSelect
                            className="outside"
                            options={inventory_type_options}
                            // disabled={true}
                            isDropdownOpen={isDropdownOpen}
                            setDropdownOpen={setDropdownOpen}
                            value={save_data?.inventory_type}
                            placeholder="Select an option..."
                            isMultiSelect={false}
                            optionName="Invontry Type"
                            callBack={(data: any) => {
                              console.log("on select sector :", data);
                              SetSaveData((prevalue) => {
                                prevalue.inventory_type = data.data;
                                return { ...prevalue };
                              });
                            }}
                          />
                        </div>

                        {/* Batch Number */}
                        <div className="position-relative mt-4 w-50">
                          <input
                            type="text"
                            className="outside"
                            // disabled
                            value={save_data?.batch_number}
                            onChange={(e) => {
                              SetSaveData((prevalue) => {
                                prevalue.batch_number = e.target.value;
                                return { ...prevalue };
                              });
                            }}
                          />
                          <span className="floating-label-outside l-7px">
                            Batch Number
                          </span>
                        </div>
                      </div>
                      {/* Date */}
                      <div className="d-flex align-items-center w-100">
                        {/* Manufature Date */}
                        <div className="position-relative mt-4 me-1 w-50">
                          <input
                            type="date"
                            className="outside"
                            // placeholder="Enter units"
                            value={save_data?.manufature_date}
                            onChange={(e: any) => {
                              SetSaveData((prevalue) => {
                                prevalue.manufature_date = e.target.value;
                                return { ...prevalue };
                              });
                            }}
                          />
                          <span className="floating-label-outside l-7px">
                            Manufature Date
                          </span>
                        </div>
                        {/* Expiry Date */}
                        <div className="position-relative mt-4 w-50">
                          <input
                            type="date"
                            className="outside"
                            value={save_data?.expiry_date}
                            onChange={(e: any) => {
                              SetSaveData((prevalue) => {
                                prevalue.expiry_date = e.target.value;
                                return { ...prevalue };
                              });
                            }}
                            // placeholder="Enter units"
                          />
                          <span className="floating-label-outside l-7px">
                            Expiry Date
                          </span>
                        </div>
                      </div>
                    </>
                  )}

                  {(save_data?.price_type.id == 5 ||
                    save_data?.price_type.id == 6) && (
                    <>
                      {/* Stock */}
                      <div className="position-relative mt-4">
                        <input
                          type="number"
                          className="outside"
                          required
                          value={save_data?.stock}
                          onChange={(e: any) => {
                            SetSaveData((prevalue) => {
                              prevalue.stock = e.target.value;
                              return { ...prevalue };
                            });
                          }}
                        />
                        <span className="floating-label-outside">Stock</span>
                      </div>
                      {/* Invontry Type */}
                      <div className="d-flex align-items-center w-100">
                        <div className="position-relative mt-4 me-1 w-50">
                          <BootstrapSelect
                            className="outside"
                            options={inventory_type_options}
                            // disabled={true}
                            isDropdownOpen={isDropdownOpen}
                            setDropdownOpen={setDropdownOpen}
                            value={save_data?.inventory_type}
                            placeholder="Select an option..."
                            isMultiSelect={false}
                            optionName="Invontry Type"
                            callBack={(data: any) => {
                              console.log("on select sector :", data);
                              SetSaveData((prevalue) => {
                                prevalue.inventory_type = data.data;
                                return { ...prevalue };
                              });
                            }}
                          />
                        </div>

                        {/* Batch Number */}
                        <div className="position-relative mt-4 me-1 w-50">
                          <input
                            type="text"
                            className="outside"
                            // disabled
                            value={save_data?.batch_number}
                            onChange={(e) => {
                              SetSaveData((prevalue) => {
                                prevalue.batch_number = e.target.value;
                                return { ...prevalue };
                              });
                            }}
                          />
                          <span className="floating-label-outside l-7px">
                            Batch Number
                          </span>
                        </div>
                      </div>
                      {/* Date */}
                      <div className="d-flex align-items-center w-100">
                        {/* Manufature Date */}
                        <div className="position-relative mt-4 me-1 w-50">
                          <input
                            type="date"
                            className="outside"
                            // placeholder="Enter units"
                            value={save_data?.manufature_date}
                            onChange={(e: any) => {
                              SetSaveData((prevalue) => {
                                prevalue.manufature_date = e.target.value;
                                return { ...prevalue };
                              });
                            }}
                          />
                          <span className="floating-label-outside l-7px">
                            Manufature Date
                          </span>
                        </div>
                        {/* Expiry Date */}
                        <div className="position-relative mt-4 me-1 w-50">
                          <input
                            type="date"
                            className="outside"
                            value={save_data?.expiry_date}
                            onChange={(e: any) => {
                              SetSaveData((prevalue) => {
                                prevalue.expiry_date = e.target.value;
                                return { ...prevalue };
                              });
                            }}
                            // placeholder="Enter units"
                          />
                          <span className="floating-label-outside l-7px">
                            Expiry Date
                          </span>
                        </div>
                      </div>
                    </>
                  )}

                  {/* Packaging Charges */}
                  <div className="fs-12px text-dark mt-4">
                    Packaging Charges
                  </div>

                  <div className="d-flex mt-3 w-100">
                    <label
                      // className="m-2"
                      className={
                        save_data.pc_type == 2
                          ? "w-50 bg-blue-500 text-white"
                          : "w-50"
                      }
                    >
                      <input
                        type="radio"
                        value={2}
                        checked={save_data.pc_type == 2}
                        onChange={(e) => {
                          SetSaveData((prevalue: any) => {
                            let value = e.target.value;
                            prevalue.pc_type = value;
                            return { ...prevalue };
                          });
                        }}
                        className="mr-2"
                      />
                      Fixed Charges
                    </label>

                    <label
                      // className="m-2"
                      className={
                        save_data.pc_type == 1
                          ? "w-50 bg-blue-500 text-white"
                          : "w-50"
                      }
                    >
                      <input
                        type="radio"
                        value={1}
                        checked={save_data.pc_type == 1}
                        onChange={(e) => {
                          SetSaveData((prevalue: any) => {
                            let value = e.target.value;
                            prevalue.pc_type = value;
                            return { ...prevalue };
                          });
                        }}
                        className="mr-2"
                      />
                      Percentage Charges
                    </label>
                  </div>

                  <div className="d-flex align-items-center">
                    {/* GST */}
                    <div className="position-relative mt-4 me-1">
                      <input
                        type="number"
                        className="outside icon-padding"
                        value={save_data?.pc_gst}
                        onChange={(e: any) => {
                          SetSaveData((prevalue: any) => {
                            let value = e.target.value;
                            prevalue.pc_gst = value;
                            return { ...prevalue };
                          });
                        }}
                      />
                      <span className="floating-label-outside l-7px">
                        Packaging GST %
                      </span>
                    </div>

                    {/* amount */}
                    <div className="position-relative mt-4">
                      <input
                        type="number"
                        className="outside icon-padding"
                        value={save_data?.pc_amount}
                        onChange={(e: any) => {
                          SetSaveData((prevalue: any) => {
                            let value = e.target.value;
                            prevalue.pc_amount = value;
                            return { ...prevalue };
                          });
                        }}
                      />
                      <span className="input-icon-absoulate">₹</span>
                      <span className="floating-label-outside l-7px">
                        Packaging Amount
                      </span>
                    </div>
                  </div>

                  <div className="mt-5 mb-2">
                    <button
                      className={
                        pc_bottom_sheet_data.data.c_from == "new"
                          ? isFormValidNew()
                            ? "primary-button"
                            : "disabled-button"
                          : isFormValid()
                          ? "primary-button"
                          : "disabled-button"
                      }
                      disabled={
                        pc_bottom_sheet_data.data.c_from == "new"
                          ? !isFormValidNew()
                          : !isFormValid()
                      }
                      onClick={() => {
                        // if(isFormValid){
                        //   console.log("save_data :",save_data)
                        // }
                        console.log("save_data :", save_data);
                        if (pc_bottom_sheet_data.data.c_from == "new") {
                          create_collection();
                        } else {
                          let send_data = {
                            collection_id:
                              pc_bottom_sheet_data.data.collection_id,
                          };
                          save_product(send_data, save_data);
                        }
                      }}
                    >
                      Create Product
                    </button>
                  </div>
                </div>
              </div>
              {/* )} */}
            </div>
          </SwipeableBottomSheet>
        </div>
      ) : null}

      {/* product create form edit*/}
      {pc_edit_bottom_sheet_data.is_open ? (
        <div className="out_of_stock_swipe">
          <SwipeableBottomSheet
            overflowHeight={0}
            overlay={true}
            style={{ zIndex: 1300 }}
            open={pc_edit_bottom_sheet_data.is_open}
            onClose={() => {
              SetPCEditBottomSheetData((prevalue: any) => {
                prevalue.is_open = false;
                prevalue.data = {};
                prevalue.all_data = {};
                return { ...prevalue };
              });
              // setTimeout(() => {
              //   SetSaveData({});
              // }, 300);
            }}
            onChange={() => {
              SetPCEditBottomSheetData((prevalue: any) => {
                prevalue.is_open = false;
                prevalue.data = {};
                prevalue.all_data = {};
                return { ...prevalue };
              });
              // setTimeout(() => {
              //   SetSaveData({});
              // }, 300);
            }}
          >
            <div>
              <div className="w-100 mt-2 d-flex bg-fff center sticky-sm">
                <hr className="hr-drawer" />
              </div>
              <div
                className="bg-fff p-2 mb-5"
                key={pc_bottom_sheet_data.data.key}
              >
                <div className="d-flex px-2 center">
                  <h6>Edit</h6>
                  <div className="ms-auto">
                    <button
                      type="button"
                      className="btn-close"
                      aria-label="Close"
                      onClick={() => {
                        SetPCEditBottomSheetData((prevalue: any) => {
                          prevalue.is_open = false;
                          prevalue.data = {};
                          prevalue.all_data = {};
                          return { ...prevalue };
                        });
                      }}
                    ></button>
                  </div>
                </div>

                {/* price */}
                {/* <div className="p-2 bg-fff  pt-3">
                  <div className="fs-12px text-dark mt-4">
                    Price Type  : {pc_bottom_sheet_data?.all_data?.price_type}
                  </div>
                  
                </div> */}

                {/* Fields */}
                <div className="p-2 bg-fff  pt-3">
                  <div className="fs-12px text-dark mt-4">
                    Packaging Charges
                  </div>

                  <div className="d-flex mt-3 w-100">
                    <label
                      // className="m-2"
                      className={
                        pc_edit_bottom_sheet_data.data.pc_type == 2
                          ? "w-50 bg-blue-500 text-white"
                          : "w-50"
                      }
                    >
                      <input
                        type="radio"
                        value={2}
                        checked={pc_edit_bottom_sheet_data.data.pc_type == 2}
                        onChange={(e) => {
                          SetPCEditBottomSheetData((prevalue: any) => {
                            let value = e.target.value;
                            prevalue.data.pc_type = value;
                            return { ...prevalue };
                          });
                        }}
                        className="mr-2"
                      />
                      Fixed Charges
                    </label>

                    <label
                      // className="m-2"
                      className={
                        pc_edit_bottom_sheet_data.data.pc_type == 1
                          ? "w-50 bg-blue-500 text-white"
                          : "w-50"
                      }
                    >
                      <input
                        type="radio"
                        value={1}
                        checked={pc_edit_bottom_sheet_data.data.pc_type == 1}
                        onChange={(e) => {
                          console.log("e", e.target.value);
                          SetPCEditBottomSheetData((prevalue: any) => {
                            let value = e.target.value;
                            prevalue.data.pc_type = value;
                            return { ...prevalue };
                          });
                        }}
                        className="mr-2"
                      />
                      Percentage Charges
                    </label>
                  </div>

                  <div className="d-flex align-items-center">
                    {/* GST */}
                    <div className="position-relative mt-4 me-2">
                      <input
                        type="number"
                        className="outside icon-padding"
                        value={pc_edit_bottom_sheet_data?.data?.pc_gst}
                        onChange={(e: any) => {
                          // SetSaveData((prevalue: any) => {
                          //   let value = e.target.value;
                          //   prevalue.p_gst = value;
                          //   return { ...prevalue };
                          // });
                          SetPCEditBottomSheetData((prevalue: any) => {
                            let value = e.target.value;
                            prevalue.data.pc_gst = value;
                            return { ...prevalue };
                          });
                        }}
                      />
                      <span className="floating-label-outside l-7px">
                        Packaging GST %
                      </span>
                    </div>

                    {/* amount */}
                    <div className="position-relative mt-4 me-2">
                      <input
                        type="number"
                        className="outside icon-padding"
                        value={pc_edit_bottom_sheet_data?.data?.pc_amount}
                        onChange={(e: any) => {
                          // SetSaveData((prevalue: any) => {
                          //   let value = e.target.value;
                          //   prevalue.pc_amount = value;
                          //   return { ...prevalue };
                          // });
                          SetPCEditBottomSheetData((prevalue: any) => {
                            let value = e.target.value;
                            prevalue.data.pc_amount = value;
                            return { ...prevalue };
                          });
                        }}
                      />
                      <span className="input-icon-absoulate">₹</span>
                      <span className="floating-label-outside l-7px">
                        Packaging Amount *
                      </span>
                    </div>
                  </div>

                  <div className="mt-5 mb-2">
                    <button
                      // className={
                      //   isEditFormValid() ? "primary-button" : "disabled-button"
                      // }
                      // disabled={!isEditFormValid()}
                      className="primary-button"
                      onClick={() => {
                        console.log("Update :");
                        let send_data: any = {
                          product_type: pc_edit_bottom_sheet_data.all_data.type,
                          type: pc_edit_bottom_sheet_data.data.pc_type,
                          gst: pc_edit_bottom_sheet_data.data.pc_gst,
                          amount: pc_edit_bottom_sheet_data.data.pc_amount,
                        };
                        if (
                          pc_edit_bottom_sheet_data.all_data.type == "product"
                        ) {
                          send_data.id =
                            pc_edit_bottom_sheet_data.all_data.product_id;
                        } else {
                          send_data.id =
                            pc_edit_bottom_sheet_data.all_data.product_group_id;
                        }
                        update_package_charge(send_data);
                      }}
                    >
                      Update
                    </button>
                  </div>
                </div>
              </div>
              {/* )} */}
            </div>
          </SwipeableBottomSheet>
        </div>
      ) : null}

      {product_detail_bottom_sheet_data.is_open ? (
        <div className="out_of_stock_swipe">
          <SwipeableBottomSheet
            overflowHeight={0}
            overlay={true}
            style={{ zIndex: 1300 }}
            open={product_detail_bottom_sheet_data.is_open}
            onClose={() => {
              SetProductDetailBottomSheetData((prevalue: any) => {
                prevalue.is_open = false;
                prevalue.all_data = {};
                return { ...prevalue };
              });
            }}
            onChange={() => {
              SetProductDetailBottomSheetData((prevalue: any) => {
                prevalue.is_open = false;
                prevalue.all_data = {};
                return { ...prevalue };
              });
            }}
          >
            <div>
              <div className="w-100 mt-2 d-flex bg-fff center sticky-sm">
                <hr className="hr-drawer" />
              </div>
              <div
                className="bg-fff p-2 mb-5"
                // key={pc_bottom_sheet_data.data.key}
              >
                <div className="d-flex px-2 center">
                  <h6>Product Details</h6>
                  <div className="ms-auto">
                    <button
                      type="button"
                      className="btn-close"
                      aria-label="Close"
                      onClick={() => {
                        SetPCEditBottomSheetData((prevalue: any) => {
                          prevalue.is_open = false;
                          prevalue.data = {};
                          prevalue.all_data = {};
                          return { ...prevalue };
                        });
                      }}
                    ></button>
                  </div>
                </div>

                <div className="mb-5">
                  {/* price type */}
                  <div className="position-relative mt-4">
                    <BootstrapSelect
                      className="outside"
                      isDropdownOpen={isDropdownOpen}
                      setDropdownOpen={setDropdownOpen}
                      value={getPriceTypeValue(
                        product_detail_bottom_sheet_data?.all_data?.price_type
                      )}
                      options={price_type_options}
                      disabled={true}
                      placeholder="Select an option..."
                      isMultiSelect={false}
                      optionName="Price Type *"
                      callBack={(data: any) => {
                        console.log("on select sector :", data);

                        // SetSaveData((prevalue) => {
                        //   prevalue.price_type = data.data;
                        //   return { ...prevalue };
                        // });
                      }}
                    />
                  </div>

                  {/* Maximum Selling Price */}
                  <div className="fs-12px text-dark mt-4">
                    Maximum Selling Price
                  </div>

                  <div className="d-flex align-items-center">
                    {/* without tax */}
                    <div className="position-relative mt-4 me-1">
                      <input
                        ref={(el) => (numInputsRef.current[3] = el)}
                        type="number"
                        className="outside icon-padding"
                        value={getDetailsValue({
                          from: "pwt",
                          data: product_detail_bottom_sheet_data.all_data,
                        })}
                        disabled
                        onChange={(e: any) => {}}
                      />
                      <span className="input-icon-absoulate">₹</span>
                      <span className="floating-label-outside l-7px">
                        Price Without Tax
                      </span>
                    </div>

                    {/* with tax */}
                    <div className="position-relative mt-4">
                      <input
                        ref={(el) => (numInputsRef.current[2] = el)}
                        type="number"
                        className="outside icon-padding"
                        value={save_data?.pricing_with_tax?.selling_price}
                        disabled
                        onChange={(e: any) => {
                          let send_data = {
                            from: "price_with_tax",
                            value: e.target.value,
                          };
                          calculate_price(send_data);
                        }}
                      />
                      <span className="input-icon-absoulate">₹</span>
                      <span className="floating-label-outside l-7px">
                        Price With Tax
                      </span>
                    </div>
                  </div>
                  {/*  */}

                  {/* Strike Price */}
                  <div className="fs-12px text-dark mt-4">Strike Price</div>

                  <div className="d-flex align-items-center">
                    {/* without tax */}
                    <div className="position-relative mt-4 me-1">
                      <input
                        ref={(el) => (numInputsRef.current[3] = el)}
                        type="number"
                        className="outside icon-padding"
                        value={save_data?.strike_price_without_tax}
                        disabled
                        onChange={(e: any) => {
                          let send_data = {
                            from: "strike_without_tax",
                            value: e.target.value,
                          };
                          calculate_price(send_data);
                        }}
                      />
                      <span className="input-icon-absoulate">₹</span>
                      <span className="floating-label-outside l-7px">
                        Without Tax
                      </span>
                    </div>

                    {/* with tax */}
                    <div className="position-relative mt-4">
                      <input
                        ref={(el) => (numInputsRef.current[2] = el)}
                        type="number"
                        className="outside icon-padding"
                        value={save_data?.strike_price_with_tax}
                        disabled
                        onChange={(e: any) => {
                          let send_data = {
                            from: "strike_with_tax",
                            value: e.target.value,
                          };
                          calculate_price(send_data);
                        }}
                      />
                      <span className="input-icon-absoulate">₹</span>
                      <span className="floating-label-outside l-7px">
                        With Tax
                      </span>
                    </div>
                  </div>
                  {/*  */}

                  {/* stock */}
                  <div className="position-relative mt-4">
                    <input
                      type="number"
                      className="outside"
                      required
                      disabled
                      value={save_data?.stock}
                      onChange={(e: any) => {
                        SetSaveData((prevalue) => {
                          prevalue.stock = e.target.value;
                          return { ...prevalue };
                        });
                      }}
                    />
                    <span className="floating-label-outside">Stock</span>
                  </div>
                </div>
              </div>
            </div>
          </SwipeableBottomSheet>
        </div>
      ) : null}

      {/* gallery */}
      {modal_gallery.is_open && (
        <ModalAddGallery
          open={modal_gallery.is_open}
          // data={modal_gallery.data}
          type={modal_gallery.type}
          data={modal_gallery.data}
          selected={modal_gallery.selected_images}
          from={"product_detail"}
          close={(data: any) => {
            console.log("Modal user on close :", data);
            SetModalGallery((prevalue) => {
              prevalue.is_open = false;
              prevalue.data = {};
              prevalue.selected_images = [];
              return { ...prevalue };
            });
            if (data.action == "save") {
              SetImgBaseUrl(data.image_base_url);
              SetSaveData((prevValue: any) => {
                prevValue.selected_files = data.value;
                return { ...prevValue };
              });
            }
          }}
        />
      )}

      {/* Menu */}
      <div className={"bottom_data"}>
        <div className="md-none center">
          {main_data.length > 0 && open_collection_menu == null ? (
            <div className={"center bg-none menu-close-btn"}>
              <button
                className="btn d-flex bg-dark align-center"
                onClick={(e) => {
                  SetOpenCollectionMenu(e.currentTarget);
                }}
              >
                <img
                  src={
                    AppConfig.CDN_Media_url +
                    "e4f42080d124bbd465d7d59837afcab0.svg"
                  }
                  className="w20px"
                />
                <h6 className="ps-1 mb-0">Menu</h6>
              </button>
            </div>
          ) : null}
        </div>
      </div>

      {/* menu open */}
      {open_collection_menu != null ? (
        <div className="food-menu-open">
          {/* floting Menu */}
          {/* <Menu
            anchorEl={open_collection_menu}
            keepMounted
            open={Boolean(open_collection_menu)}
            onClose={menuClose}
            elevation={0}
            getContentAnchorEl={null}
            transitionduration={0}
            anchororigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
            transformorigin={{
              vertical: "top",
              horizontal: "center",
            }}
            className="food-menu-open"
          > */}
          <Modal
            open={Boolean(open_collection_menu)}
            className="px-md-2 px-sm-0"
          >
            <div className="bottom_data">
              <div
                className="center l-0 mb-5" //     ? //   footer_open_cls == "footer_visible" // {
                // : "footer-hidden-center mb-5"
                // }
              >
                <div className="bg-white radius mb-3 w-100 w-md-80">
                  <div className="menu-card-new p-3 border-top-radius w-md-80">
                    <h5 className="fw-bold menu-center px-2">
                      <span className="px-2">Menu</span>
                    </h5>
                  </div>
                  <div className="scroll60">
                    {main_data?.map((items: any, index: any) => (
                      <MenuItem key={index} selected={index === selected_index}>
                        <Scroll.Link
                          className="flex"
                          activeclassName="scroll-active"
                          to={items.collection_id}
                          spy={true}
                          smooth={true}
                          offset={-200}
                          duration={500}
                          onClick={(event: any) => {
                            // SetVisibilitySensorActive(false);
                            SetSelectedIndex(index);
                            menuClose();
                          }}
                        >
                          <p className="py-2 cursor scroll-active ">
                            {items.collection_name}
                          </p>
                        </Scroll.Link>
                      </MenuItem>
                    ))}
                  </div>
                  <div className="bottom_data">
                    <div
                      className="center l-0" //     ? //   footer_open_cls == "footer_visible" // {
                      //     : "footer-hidden-center"
                      // }
                    >
                      <button
                        className="btn d-flex bg-dark align-center"
                        onClick={(e) => {
                          menuClose();
                        }}
                      >
                        <img
                          src={
                            AppConfig.CDN_Media_url +
                            "b960e2388e4e92aa031a5adf38d4a31f.svg"
                          }
                          className="w20px"
                        />

                        {/* <Ionicons name="close" size={18} color="white" /> */}
                        <h6 className="ps-2 mb-0 text-white">Close</h6>
                      </button>
                    </div>
                  </div>
                  {/* <div className="bottom-data">
              <div className="center-menu center l-0">
                <button
                  className="btn flex bg-dark align-center"
                  onClick={(e) => {
                    menuClose();
                    console.log("Menu close clicked!");
                  }}
                >
                  <img
                    src={
                      AppConfig.CDN_Media_url +
                      "b960e2388e4e92aa031a5adf38d4a31f.svg"
                    }
                    className="w-20"
                  />
                  <h6 className="ps-2 mb-0 text-white">Close</h6>
                </button>
              </div>
              </div> */}
                </div>
              </div>
            </div>
          </Modal>
          {/* </Menu> */}
        </div>
      ) : null}

      {/* bottom sheet available slot */}
      {bottom_sheet_data.is_open ? (
        <div className="out_of_stock_swipe">
          <SwipeableBottomSheet
            overflowHeight={0}
            overlay={true}
            style={{ zIndex: 1300 }}
            open={bottom_sheet_data.is_open}
            onClose={() => {
              SetBottomSheetData((prevalue: any) => {
                prevalue.is_open = false;
                prevalue.data = {};
                return { ...prevalue };
              });
              SetUncheckRadioValue({
                checked_value: "",
                other_value: "",
              });
            }}
            onChange={() => {
              // SetFilterOpen(false)
              SetBottomSheetData((prevalue: any) => {
                prevalue.is_open = false;
                prevalue.data = {};
                return { ...prevalue };
              });
              SetUncheckRadioValue({
                checked_value: "",
                other_value: "",
              });
            }}
          >
            <div
              className="bg-fff radius-top"
              style={{ minHeight: "500px", maxHeight: "90vh" }}
            >
              <div className="">
                <div className="w-100 center">
                  <hr className="hr-drawer" />
                </div>
                {bottom_sheet_data.data.from != "switch_on" ? (
                  <div className="p-3">
                    <h6 className="fs-5 fw-600">Auto turn on after</h6>
                  </div>
                ) : (
                  <div className="p-3">
                    <h6 className="fs-5 fw-600">Turning ON Beyond Schedule</h6>
                    <p className="mt-2">
                      Re-enable Regular schedule automatically after
                    </p>
                  </div>
                )}
              </div>
              <div className="p-3">
                <div className="mb-3 row">
                  {/* 2 hours */}
                  <label className="form-label d-flex align-items-center mb-3 cursor">
                    <input
                      type="radio"
                      className="form-check-input me-2"
                      name={"2 Hours"}
                      checked={
                        uncheck_radio_value.checked_value == "2 Hours"
                          ? true
                          : false
                      }
                      onChange={(event) => {
                        console.log("name event :", event);
                        SetUncheckRadioValue((preavlue: any) => {
                          preavlue.checked_value = event.target.name;
                          preavlue.other_value = "";
                          return { ...preavlue };
                        });
                      }}
                    />
                    <p className="capitalize">2 Hours</p>
                  </label>
                  {/* 4 Hours */}
                  <label className="form-label d-flex align-items-center mb-3 cursor">
                    <input
                      type="radio"
                      className="form-check-input me-2"
                      name={"4 Hours"}
                      checked={
                        uncheck_radio_value.checked_value == "4 Hours"
                          ? true
                          : false
                      }
                      // value={ele.id}
                      onChange={(event) => {
                        SetUncheckRadioValue((preavlue: any) => {
                          preavlue.checked_value = event.target.name;
                          preavlue.other_value = "";
                          return { ...preavlue };
                        });
                      }}
                    />
                    <p className="capitalize">4 Hours</p>
                  </label>
                  {/* 1 Day */}
                  <label className="form-label d-flex align-items-center mb-3 cursor">
                    <input
                      type="radio"
                      className="form-check-input me-2"
                      name={"1 Day"}
                      checked={
                        uncheck_radio_value.checked_value == "1 Day"
                          ? true
                          : false
                      }
                      // value={ele.id}
                      onChange={(event) => {
                        SetUncheckRadioValue((preavlue: any) => {
                          preavlue.checked_value = event.target.name;
                          preavlue.other_value = "";
                          return { ...preavlue };
                        });
                      }}
                    />
                    <p className="capitalize">1 Day</p>
                  </label>
                  {/* 2 Days */}
                  <label className="form-label d-flex align-items-center mb-3 cursor">
                    <input
                      type="radio"
                      className="form-check-input me-2"
                      name={"2 Day"}
                      checked={
                        uncheck_radio_value.checked_value == "2 Day"
                          ? true
                          : false
                      }
                      // value={ele.id}
                      onChange={(event) => {
                        SetUncheckRadioValue((preavlue: any) => {
                          preavlue.checked_value = event.target.name;
                          preavlue.other_value = "";
                          return { ...preavlue };
                        });
                      }}
                    />
                    <p className="capitalize">2 Days </p>
                  </label>
                  {/* Next Available Slot */}
                  <label
                    className={
                      bottom_sheet_data.data.p_ele.channel_unavailable.hasOwnProperty(
                        "next_slot"
                      )
                        ? "form-label d-flex align-items-center mb-3 cursor"
                        : "form-label d-flex align-items-center mb-3 cursor border-light-gray"
                    }
                  >
                    <input
                      type="radio"
                      className="form-check-input me-2"
                      name={"Next Available Slot"}
                      disabled={
                        bottom_sheet_data.data.p_ele.channel_unavailable.hasOwnProperty(
                          "next_slot"
                        )
                          ? false
                          : true
                      }
                      checked={
                        uncheck_radio_value.checked_value ==
                        "Next Available Slot"
                          ? true
                          : false
                      }
                      // value={ele.id}
                      onChange={(event) => {
                        SetUncheckRadioValue((preavlue: any) => {
                          preavlue.checked_value = event.target.name;
                          preavlue.other_value = "";
                          return { ...preavlue };
                        });
                      }}
                    />
                    <p
                      className={
                        // checked == false
                        bottom_sheet_data.data.p_ele.channel_unavailable.hasOwnProperty(
                          "next_slot"
                        )
                          ? "capitalize"
                          : "capitalize text-light-gray"
                      }
                    >
                      {bottom_sheet_data.data.p_ele.channel_unavailable.hasOwnProperty(
                        "next_slot"
                      )
                        ? "Next Available Slot At " +
                          bottom_sheet_data.data.p_ele.channel_unavailable
                            .next_slot
                        : "Next Available Slot"}
                    </p>
                  </label>
                  {/* Custom Date & Time */}
                  <label className="form-label d-flex align-items-center mb-3 cursor">
                    <input
                      type="radio"
                      className="form-check-input me-2"
                      name={"Custom Date & Time"}
                      // value={ele.id}
                      checked={
                        uncheck_radio_value.checked_value ==
                        "Custom Date & Time"
                          ? true
                          : false
                      }
                      onChange={(event) => {
                        SetUncheckRadioValue((preavlue: any) => {
                          preavlue.checked_value = event.target.name;
                          preavlue.other_value = "";
                          return { ...preavlue };
                        });
                      }}
                    />
                    <p className="capitalize">Custom Date & Time</p>
                  </label>

                  {uncheck_radio_value.checked_value == "Custom Date & Time" ? (
                    <div className="px-0 w-50">
                      <input
                        type="datetime-local"
                        className="me-2 form-control"
                        min={new Date().toJSON().slice(0, 10)}
                        onChange={(event: any) => {
                          console.log("date event :", event);
                          let value = event.target.value;
                          let formattedDate = value.split("T");
                          console.log("formattedDate :", formattedDate);
                          var timeSplit = formattedDate[1].split(":");
                          var hours = parseInt(timeSplit[0]);
                          var minutes: any = parseInt(timeSplit[1]);

                          // Determine AM or PM
                          var period = hours >= 12 ? "PM" : "AM";

                          // Convert hours to 12-hour format
                          hours = hours % 12;
                          hours = hours ? hours : 12; // 0 should be 12

                          // Add leading zero to minutes if needed
                          minutes = minutes < 10 ? "0" + minutes : minutes;

                          // Return the formatted time
                          let time_value = hours + ":" + minutes + " " + period;
                          console.log("time_value :", time_value);
                          let other_value = formattedDate[0] + " " + time_value;
                          console.log("other_value :", other_value);
                          SetUncheckRadioValue((preavlue: any) => {
                            // preavlue.checked_value = event.target.name;
                            preavlue.other_value = other_value;
                            return { ...preavlue };
                          });
                        }}
                      />
                    </div>
                  ) : null}
                </div>
                {/* Manualy turn on / off */}
                {bottom_sheet_data.data.from != "switch_on" ? (
                  <div className="d-flex ">
                    <small className="text-black fw-bold">
                      Manualy turn on
                    </small>
                  </div>
                ) : (
                  <div className="d-flex ">
                    <small className="text-black fw-bold">
                      Manualy turn off
                    </small>
                  </div>
                )}

                {/* I will turn it on/off myself */}
                <div className="my-3">
                  <label className="form-label d-flex align-items-center mb-3 cursor">
                    <input
                      type="radio"
                      className="form-check-input me-2"
                      name={"I will turn it on myself"}
                      // value={ele.id}
                      checked={
                        uncheck_radio_value.checked_value ==
                        "I will turn it on myself"
                          ? true
                          : false
                      }
                      onChange={(event) => {
                        SetUncheckRadioValue((preavlue: any) => {
                          preavlue.checked_value = event.target.name;
                          preavlue.other_value = "";
                          return { ...preavlue };
                        });
                      }}
                    />
                    {bottom_sheet_data.data.from != "switch_on" ? (
                      <p className="capitalize">I will turn it on myself</p>
                    ) : (
                      <p className="capitalize">I will turn it off myself</p>
                    )}
                  </label>
                </div>
              </div>
              <div className="p-3">
                <div className="end">
                  <button
                    className="btn btn-primary btn-lg btn-block"
                    onClick={() => {
                      // submit_uncheck_time();
                      console.log("on bottom sheet ok");
                      if (bottom_sheet_data.data.p_ele.type == "product") {
                        let push_value: any = {
                          outlet_channel_product_variants_available: {
                            [bottom_sheet_data.data.p_ele.save_id]: {
                              value: "",
                            },
                          },
                        };
                        SetUncheckRadioValue((radio_prevalue: any) => {
                          if (radio_prevalue.checked_value == "2 Hours") {
                            if (bottom_sheet_data.data.from != "switch_on") {
                              push_value.outlet_channel_product_variants_available[
                                bottom_sheet_data.data.p_ele.save_id
                              ] = {
                                value: 3,
                                hours: 2,
                              };
                            } else {
                              push_value.outlet_channel_product_variants_available[
                                bottom_sheet_data.data.p_ele.save_id
                              ] = {
                                value: 9,
                                hours: 2,
                              };
                            }
                          }
                          if (radio_prevalue.checked_value == "4 Hours") {
                            if (bottom_sheet_data.data.from != "switch_on") {
                              push_value.outlet_channel_product_variants_available[
                                bottom_sheet_data.data.p_ele.save_id
                              ] = {
                                value: 3,
                                hours: 4,
                              };
                            } else {
                              push_value.outlet_channel_product_variants_available[
                                bottom_sheet_data.data.p_ele.save_id
                              ] = {
                                value: 9,
                                hours: 4,
                              };
                            }
                          }
                          if (radio_prevalue.checked_value == "1 Day") {
                            if (bottom_sheet_data.data.from != "switch_on") {
                              push_value.outlet_channel_product_variants_available[
                                bottom_sheet_data.data.p_ele.save_id
                              ] = {
                                value: 4,
                                days: 1,
                              };
                            } else {
                              push_value.outlet_channel_product_variants_available[
                                bottom_sheet_data.data.p_ele.save_id
                              ] = {
                                value: 10,
                                days: 1,
                              };
                            }
                          }
                          if (radio_prevalue.checked_value == "2 Day") {
                            if (bottom_sheet_data.data.from != "switch_on") {
                              push_value.outlet_channel_product_variants_available[
                                bottom_sheet_data.data.p_ele.save_id
                              ] = {
                                value: 4,
                                days: 2,
                              };
                            } else {
                              push_value.outlet_channel_product_variants_available[
                                bottom_sheet_data.data.p_ele.save_id
                              ] = {
                                value: 10,
                                days: 2,
                              };
                            }
                          }
                          if (
                            radio_prevalue.checked_value ==
                            "Next Available Slot"
                          ) {
                            if (bottom_sheet_data.data.from != "switch_on") {
                              push_value.outlet_channel_product_variants_available[
                                bottom_sheet_data.data.p_ele.save_id
                              ] = {
                                value: 6,
                              };
                            } else {
                              push_value.outlet_channel_product_variants_available[
                                bottom_sheet_data.data.p_ele.save_id
                              ] = {
                                value: 12,
                              };
                            }
                          }
                          if (
                            radio_prevalue.checked_value == "Custom Date & Time"
                          ) {
                            if (bottom_sheet_data.data.from != "switch_on") {
                              push_value.outlet_channel_product_variants_available[
                                bottom_sheet_data.data.p_ele.save_id
                              ] = {
                                value: 5,
                                time: radio_prevalue.other_value,
                              };
                            } else {
                              push_value.outlet_channel_product_variants_available[
                                bottom_sheet_data.data.p_ele.save_id
                              ] = {
                                value: 11,
                                time: radio_prevalue.other_value,
                              };
                            }
                          }
                          if (
                            radio_prevalue.checked_value ==
                            "I will turn it on myself"
                          ) {
                            if (bottom_sheet_data.data.from != "switch_on") {
                              push_value.outlet_channel_product_variants_available[
                                bottom_sheet_data.data.p_ele.save_id
                              ] = {
                                value: 2,
                              };
                            } else {
                              push_value.outlet_channel_product_variants_available[
                                bottom_sheet_data.data.p_ele.save_id
                              ] = {
                                value: 7,
                              };
                            }
                          }
                          console.log("push_value :", push_value);
                          available_submit(
                            push_value,
                            bottom_sheet_data.data.p_ele.type,
                            bottom_sheet_data.data.p_ele,
                            bottom_sheet_data.data.p_index,
                            bottom_sheet_data.data.key
                          );
                          return { ...radio_prevalue };
                        });
                      }
                      if (
                        bottom_sheet_data.data.p_ele.type == "product_group"
                      ) {
                        let push_value: any = {
                          outlet_channel_products_available: {
                            [bottom_sheet_data.data.p_ele.save_id]: {
                              value: "",
                            },
                          },
                          // outlet_channel_variants_available: {},
                        };
                        SetUncheckRadioValue((radio_prevalue: any) => {
                          if (radio_prevalue.checked_value == "2 Hours") {
                            if (bottom_sheet_data.data.from != "switch_on") {
                              push_value.outlet_channel_products_available[
                                bottom_sheet_data.data.p_ele.save_id
                              ] = {
                                value: 3,
                                hours: 2,
                              };
                            } else {
                              push_value.outlet_channel_products_available[
                                bottom_sheet_data.data.p_ele.save_id
                              ] = {
                                value: 9,
                                hours: 2,
                              };
                            }
                          }
                          if (radio_prevalue.checked_value == "4 Hours") {
                            if (bottom_sheet_data.data.from != "switch_on") {
                              push_value.outlet_channel_products_available[
                                bottom_sheet_data.data.p_ele.save_id
                              ] = {
                                value: 3,
                                hours: 4,
                              };
                            } else {
                              push_value.outlet_channel_products_available[
                                bottom_sheet_data.data.p_ele.save_id
                              ] = {
                                value: 9,
                                hours: 4,
                              };
                            }
                          }
                          if (radio_prevalue.checked_value == "1 Day") {
                            if (bottom_sheet_data.data.from != "switch_on") {
                              push_value.outlet_channel_products_available[
                                bottom_sheet_data.data.p_ele.save_id
                              ] = {
                                value: 4,
                                days: 1,
                              };
                            } else {
                              push_value.outlet_channel_products_available[
                                bottom_sheet_data.data.p_ele.save_id
                              ] = {
                                value: 10,
                                days: 1,
                              };
                            }
                          }
                          if (radio_prevalue.checked_value == "2 Day") {
                            if (bottom_sheet_data.data.from != "switch_on") {
                              push_value.outlet_channel_products_available[
                                bottom_sheet_data.data.p_ele.save_id
                              ] = {
                                value: 4,
                                days: 2,
                              };
                            } else {
                              push_value.outlet_channel_products_available[
                                bottom_sheet_data.data.p_ele.save_id
                              ] = {
                                value: 10,
                                days: 2,
                              };
                            }
                          }
                          if (
                            radio_prevalue.checked_value ==
                            "Next Available Slot"
                          ) {
                            if (bottom_sheet_data.data.from != "switch_on") {
                              push_value.outlet_channel_products_available[
                                bottom_sheet_data.data.p_ele.save_id
                              ] = {
                                value: 6,
                              };
                            } else {
                              push_value.outlet_channel_products_available[
                                bottom_sheet_data.data.p_ele.save_id
                              ] = {
                                value: 12,
                              };
                            }
                          }
                          if (
                            radio_prevalue.checked_value == "Custom Date & Time"
                          ) {
                            if (bottom_sheet_data.data.from != "switch_on") {
                              push_value.outlet_channel_products_available[
                                bottom_sheet_data.data.p_ele.save_id
                              ] = {
                                value: 5,
                                time: radio_prevalue.other_value,
                              };
                            } else {
                              push_value.outlet_channel_products_available[
                                bottom_sheet_data.data.p_ele.save_id
                              ] = {
                                value: 11,
                                time: radio_prevalue.other_value,
                              };
                            }
                          }
                          if (
                            radio_prevalue.checked_value ==
                            "I will turn it on myself"
                          ) {
                            if (bottom_sheet_data.data.from != "switch_on") {
                              push_value.outlet_channel_products_available[
                                bottom_sheet_data.data.p_ele.save_id
                              ] = {
                                value: 2,
                              };
                            } else {
                              push_value.outlet_channel_products_available[
                                bottom_sheet_data.data.p_ele.save_id
                              ] = {
                                value: 7,
                              };
                            }
                          }
                          // bottom_sheet_data.data.p_ele.product_variants.map(
                          //   (ele: any) => {
                          //     push_value.outlet_channel_variants_available[
                          //       ele.save_id
                          //     ] = false;
                          //   }
                          // );
                          console.log("push_value :", push_value);
                          available_submit(
                            push_value,
                            bottom_sheet_data.data.p_ele.type,
                            bottom_sheet_data.data.p_ele,
                            bottom_sheet_data.data.p_index,
                            bottom_sheet_data.data.key
                          );
                          return { ...radio_prevalue };
                        });
                      }
                    }}
                  >
                    Ok
                  </button>
                </div>
              </div>
            </div>
          </SwipeableBottomSheet>
        </div>
      ) : null}

      {/* collection bottom sheet available slot */}
      {collection_bottom_sheet_data.is_open ? (
        <div className="out_of_stock_swipe">
          <SwipeableBottomSheet
            overflowHeight={0}
            overlay={true}
            style={{ zIndex: 1300 }}
            open={collection_bottom_sheet_data.is_open}
            onClose={() => {
              SetCollectionBottomSheetData((prevalue: any) => {
                prevalue.is_open = false;
                prevalue.data = {};
                return { ...prevalue };
              });
              SetCollectionUncheckRadioValue({
                checked_value: "",
                other_value: "",
              });
            }}
            onChange={() => {
              // SetFilterOpen(false)
              SetCollectionBottomSheetData((prevalue: any) => {
                prevalue.is_open = false;
                prevalue.data = {};
                return { ...prevalue };
              });
              SetCollectionUncheckRadioValue({
                checked_value: "",
                other_value: "",
              });
            }}
          >
            <div
              className="bg-fff radius-top"
              style={{ minHeight: "700px", maxHeight: "90vh" }}
            >
              <div className="">
                <div className="w-100 center">
                  <hr className="hr-drawer" />
                </div>
                {collection_bottom_sheet_data.data.from != "switch_on" ? (
                  <div className="p-3">
                    <h6 className="fs-5 fw-600">Auto turn on after</h6>
                  </div>
                ) : (
                  <div className="p-3">
                    <h6 className="fs-5 fw-600">Turning ON Beyond Schedule</h6>
                    <p className="mt-2">
                      Re-enable Regular schedule automatically after
                    </p>
                  </div>
                )}
              </div>
              <div className="p-3">
                <div className="mb-3 row">
                  {/* 2 hours */}
                  <label className="form-label d-flex align-items-center mb-3 cursor">
                    <input
                      type="radio"
                      className="form-check-input me-2"
                      name={"2 Hours"}
                      checked={
                        collection_uncheck_radio_value.checked_value ==
                        "2 Hours"
                          ? true
                          : false
                      }
                      onChange={(event) => {
                        console.log("name event :", event);
                        SetCollectionUncheckRadioValue((preavlue: any) => {
                          preavlue.checked_value = event.target.name;
                          preavlue.other_value = "";
                          return { ...preavlue };
                        });
                      }}
                    />
                    <p className="capitalize">2 Hours</p>
                  </label>
                  {/* 4 Hours */}
                  <label className="form-label d-flex align-items-center mb-3 cursor">
                    <input
                      type="radio"
                      className="form-check-input me-2"
                      name={"4 Hours"}
                      checked={
                        collection_uncheck_radio_value.checked_value ==
                        "4 Hours"
                          ? true
                          : false
                      }
                      // value={ele.id}
                      onChange={(event) => {
                        SetCollectionUncheckRadioValue((preavlue: any) => {
                          preavlue.checked_value = event.target.name;
                          preavlue.other_value = "";
                          return { ...preavlue };
                        });
                      }}
                    />
                    <p className="capitalize">4 Hours</p>
                  </label>
                  {/* 1 Day */}
                  <label className="form-label d-flex align-items-center mb-3 cursor">
                    <input
                      type="radio"
                      className="form-check-input me-2"
                      name={"1 Day"}
                      checked={
                        collection_uncheck_radio_value.checked_value == "1 Day"
                          ? true
                          : false
                      }
                      // value={ele.id}
                      onChange={(event) => {
                        SetCollectionUncheckRadioValue((preavlue: any) => {
                          preavlue.checked_value = event.target.name;
                          preavlue.other_value = "";
                          return { ...preavlue };
                        });
                      }}
                    />
                    <p className="capitalize">1 Day</p>
                  </label>
                  {/* 2 Days */}
                  <label className="form-label d-flex align-items-center mb-3 cursor">
                    <input
                      type="radio"
                      className="form-check-input me-2"
                      name={"2 Day"}
                      checked={
                        collection_uncheck_radio_value.checked_value == "2 Day"
                          ? true
                          : false
                      }
                      // value={ele.id}
                      onChange={(event) => {
                        SetCollectionUncheckRadioValue((preavlue: any) => {
                          preavlue.checked_value = event.target.name;
                          preavlue.other_value = "";
                          return { ...preavlue };
                        });
                      }}
                    />
                    <p className="capitalize">2 Days </p>
                  </label>
                  {/* Next Available Slot */}
                  {collection_bottom_sheet_data.data.from != "switch_on" &&
                    have_next_slot(collection_bottom_sheet_data.data.key) && (
                      <label
                        className={
                          // collection_bottom_sheet_data.data.p_ele.channel_unavailable.hasOwnProperty(
                          //   "next_slot"
                          // )
                          have_next_slot(collection_bottom_sheet_data.data.key)
                            ? "form-label d-flex align-items-center mb-3 cursor"
                            : "form-label d-flex align-items-center mb-3 cursor border-light-gray"
                        }
                      >
                        <input
                          type="radio"
                          className="form-check-input me-2"
                          name={"Next Available Slot"}
                          disabled={
                            have_next_slot(
                              collection_bottom_sheet_data.data.key
                            )
                              ? // collection_bottom_sheet_data.data.p_ele.channel_unavailable.hasOwnProperty(
                                //   "next_slot"
                                // )
                                false
                              : true
                          }
                          checked={
                            collection_uncheck_radio_value.checked_value ==
                            "Next Available Slot"
                              ? true
                              : false
                          }
                          // value={ele.id}
                          onChange={(event) => {
                            console.log("on next sechdule ");
                            SetCollectionUncheckRadioValue((preavlue: any) => {
                              preavlue.checked_value = event.target.name;
                              preavlue.other_value = "";
                              return { ...preavlue };
                            });
                          }}
                        />
                        <p
                          className={
                            // checked == false
                            // collection_bottom_sheet_data.data.p_ele.channel_unavailable.hasOwnProperty(
                            //   "next_slot"
                            // )
                            have_next_slot(
                              collection_bottom_sheet_data.data.key
                            )
                              ? "capitalize"
                              : "capitalize text-light-gray"
                          }
                        >
                          {/* {collection_bottom_sheet_data.data.p_ele.channel_unavailable.hasOwnProperty(
                          "next_slot"
                        )
                          ? "Next Available Slot At " +
                          collection_bottom_sheet_data.data.p_ele.channel_unavailable
                              .next_slot
                          : "Next Available Slot"} */}
                          Next Available Schedule
                        </p>
                      </label>
                    )}

                  {collection_bottom_sheet_data.data.from == "switch_on" &&
                    have_next_slot(collection_bottom_sheet_data.data.key) && (
                      <label
                        className={
                          // collection_bottom_sheet_data.data.p_ele.channel_unavailable.hasOwnProperty(
                          //   "next_slot"
                          // )

                          // have_next_slot(collection_bottom_sheet_data.data.key)

                          //   ? "form-label d-flex align-items-center mb-3 cursor"
                          //   : "form-label d-flex align-items-center mb-3 cursor border-light-gray"
                          "form-label d-flex align-items-center mb-3 cursor"
                        }
                      >
                        <input
                          type="radio"
                          className="form-check-input me-2"
                          name={"Next Available Slot"}
                          // disabled={
                          //   have_next_slot(collection_bottom_sheet_data.data.key)
                          //   // collection_bottom_sheet_data.data.p_ele.channel_unavailable.hasOwnProperty(
                          //   //   "next_slot"
                          //   // )
                          //     ? false
                          //     : true
                          // }
                          checked={
                            collection_uncheck_radio_value.checked_value ==
                            "Next Available Slot"
                              ? true
                              : false
                          }
                          // value={ele.id}
                          onChange={(event) => {
                            console.log("on next sechdule ");
                            SetCollectionUncheckRadioValue((preavlue: any) => {
                              preavlue.checked_value = event.target.name;
                              preavlue.other_value = "";
                              return { ...preavlue };
                            });
                          }}
                        />
                        <p
                          className={
                            // checked == false
                            // collection_bottom_sheet_data.data.p_ele.channel_unavailable.hasOwnProperty(
                            //   "next_slot"
                            // )
                            have_next_slot(
                              collection_bottom_sheet_data.data.key
                            )
                              ? "capitalize"
                              : "capitalize text-light-gray"
                          }
                        >
                          {/* {collection_bottom_sheet_data.data.p_ele.channel_unavailable.hasOwnProperty(
                          "next_slot"
                        )
                          ? "Next Available Slot At " +
                          collection_bottom_sheet_data.data.p_ele.channel_unavailable
                              .next_slot
                          : "Next Available Slot"} */}
                          Until Next Available Schedule
                        </p>
                      </label>
                    )}

                  {/* Custom Date & Time */}
                  <label className="form-label d-flex align-items-center mb-3 cursor">
                    <input
                      type="radio"
                      className="form-check-input me-2"
                      name={"Custom Date & Time"}
                      // value={ele.id}
                      checked={
                        collection_uncheck_radio_value.checked_value ==
                        "Custom Date & Time"
                          ? true
                          : false
                      }
                      onChange={(event) => {
                        SetCollectionUncheckRadioValue((preavlue: any) => {
                          preavlue.checked_value = event.target.name;
                          preavlue.other_value = "";
                          return { ...preavlue };
                        });
                      }}
                    />
                    <p className="capitalize">Custom Date & Time</p>
                  </label>

                  {collection_uncheck_radio_value.checked_value ==
                  "Custom Date & Time" ? (
                    <div className="px-0 w-50">
                      <input
                        type="datetime-local"
                        className="me-2 form-control"
                        min={new Date().toJSON().slice(0, 10)}
                        onChange={(event: any) => {
                          console.log("date event :", event);
                          let value = event.target.value;
                          let formattedDate = value.split("T");
                          console.log("formattedDate :", formattedDate);
                          var timeSplit = formattedDate[1].split(":");
                          var hours = parseInt(timeSplit[0]);
                          var minutes: any = parseInt(timeSplit[1]);

                          // Determine AM or PM
                          var period = hours >= 12 ? "PM" : "AM";

                          // Convert hours to 12-hour format
                          hours = hours % 12;
                          hours = hours ? hours : 12; // 0 should be 12

                          // Add leading zero to minutes if needed
                          minutes = minutes < 10 ? "0" + minutes : minutes;

                          // Return the formatted time
                          let time_value = hours + ":" + minutes + " " + period;
                          console.log("time_value :", time_value);
                          let other_value = formattedDate[0] + " " + time_value;
                          console.log("other_value :", other_value);
                          SetCollectionUncheckRadioValue((preavlue: any) => {
                            // preavlue.checked_value = event.target.name;
                            preavlue.other_value = other_value;
                            return { ...preavlue };
                          });
                        }}
                      />
                    </div>
                  ) : null}
                </div>
                {/* Manualy turn on / off */}
                {collection_bottom_sheet_data.data.from != "switch_on" ? (
                  <div className="d-flex ">
                    <small className="text-black fw-bold">
                      Manualy turn on
                    </small>
                  </div>
                ) : (
                  <div className="d-flex ">
                    <small className="text-black fw-bold">
                      Manualy turn off
                    </small>
                  </div>
                )}

                {/* I will turn it on/off myself */}
                <div className="my-3">
                  <label className="form-label d-flex align-items-center mb-3 cursor">
                    <input
                      type="radio"
                      className="form-check-input me-2"
                      name={"I will turn it on myself"}
                      // value={ele.id}
                      checked={
                        collection_uncheck_radio_value.checked_value ==
                        "I will turn it on myself"
                          ? true
                          : false
                      }
                      onChange={(event) => {
                        SetCollectionUncheckRadioValue((preavlue: any) => {
                          preavlue.checked_value = event.target.name;
                          preavlue.other_value = "";
                          return { ...preavlue };
                        });
                      }}
                    />
                    {collection_bottom_sheet_data.data.from != "switch_on" ? (
                      <p className="capitalize">I will turn it on myself</p>
                    ) : (
                      <p className="capitalize">I will turn it off myself</p>
                    )}
                  </label>
                </div>

                <div className="py-2 px-0">
                  <p className="v-small text-darkgray">
                    <span className="text-dark pe-1 fw-bold">Note:</span>
                    This item will not be visible to customers on the 1SuperApp
                    till your switch it on
                  </p>
                </div>
              </div>
              <div className="p-3">
                <div className="end">
                  <button
                    className="btn btn-primary btn-lg btn-block"
                    onClick={() => {
                      // submit_uncheck_time();
                      // console.log("on bottom sheet ok");
                      let push_value: any = {
                        status: {},
                        index: collection_bottom_sheet_data.data.p_index,
                        // outlet_channel_product_variants_available: {
                        //   [collection_bottom_sheet_data.data.p_ele.save_id]: {
                        //     value: "",
                        //   },
                        // },
                      };
                      if (
                        collection_bottom_sheet_data.data.hasOwnProperty(
                          "collection_id"
                        )
                      ) {
                        push_value.collection_id =
                          collection_bottom_sheet_data.data.collection_id;
                      }
                      SetCollectionUncheckRadioValue((radio_prevalue: any) => {
                        if (radio_prevalue.checked_value == "2 Hours") {
                          if (
                            collection_bottom_sheet_data.data.from !=
                            "switch_on"
                          ) {
                            // push_value.outlet_channel_product_variants_available[
                            //   collection_bottom_sheet_data.data.p_ele.save_id
                            // ] = {
                            //   value: 3,
                            //   hours: 2,
                            // };
                            push_value.status = {
                              value: 3,
                              hours: 2,
                            };
                          } else {
                            // push_value.outlet_channel_product_variants_available[
                            //   collection_bottom_sheet_data.data.p_ele.save_id
                            // ] = {
                            //   value: 9,
                            //   hours: 2,
                            // };
                            push_value.status = {
                              value: 9,
                              hours: 2,
                            };
                          }
                        }
                        if (radio_prevalue.checked_value == "4 Hours") {
                          if (
                            collection_bottom_sheet_data.data.from !=
                            "switch_on"
                          ) {
                            // push_value.outlet_channel_product_variants_available[
                            //   collection_bottom_sheet_data.data.p_ele.save_id
                            // ] = {
                            //   value: 3,
                            //   hours: 4,
                            // };
                            push_value.status = {
                              value: 3,
                              hours: 4,
                            };
                          } else {
                            // push_value.outlet_channel_product_variants_available[
                            //   collection_bottom_sheet_data.data.p_ele.save_id
                            // ] = {
                            //   value: 9,
                            //   hours: 4,
                            // };
                            push_value.status = {
                              value: 9,
                              hours: 4,
                            };
                          }
                        }
                        if (radio_prevalue.checked_value == "1 Day") {
                          if (
                            collection_bottom_sheet_data.data.from !=
                            "switch_on"
                          ) {
                            // push_value.outlet_channel_product_variants_available[
                            //   collection_bottom_sheet_data.data.p_ele.save_id
                            // ] = {
                            //   value: 4,
                            //   days: 1,
                            // };
                            push_value.status = {
                              value: 4,
                              days: 1,
                            };
                          } else {
                            // push_value.outlet_channel_product_variants_available[
                            //   collection_bottom_sheet_data.data.p_ele.save_id
                            // ] = {
                            //   value: 10,
                            //   days: 1,
                            // };
                            push_value.status = {
                              value: 10,
                              days: 1,
                            };
                          }
                        }
                        if (radio_prevalue.checked_value == "2 Day") {
                          if (
                            collection_bottom_sheet_data.data.from !=
                            "switch_on"
                          ) {
                            // push_value.outlet_channel_product_variants_available[
                            //   collection_bottom_sheet_data.data.p_ele.save_id
                            // ] = {
                            //   value: 4,
                            //   days: 2,
                            // };
                            push_value.status = {
                              value: 4,
                              days: 2,
                            };
                          } else {
                            // push_value.outlet_channel_product_variants_available[
                            //   collection_bottom_sheet_data.data.p_ele.save_id
                            // ] = {
                            //   value: 10,
                            //   days: 2,
                            // };
                            push_value.status = {
                              value: 10,
                              days: 2,
                            };
                          }
                        }
                        if (
                          radio_prevalue.checked_value == "Next Available Slot"
                        ) {
                          if (
                            collection_bottom_sheet_data.data.from !=
                            "switch_on"
                          ) {
                            // push_value.outlet_channel_product_variants_available[
                            //   collection_bottom_sheet_data.data.p_ele.save_id
                            // ] = {
                            //   value: 6,
                            // };
                            push_value.status = {
                              value: 6,
                            };
                          } else {
                            // push_value.outlet_channel_product_variants_available[
                            //   collection_bottom_sheet_data.data.p_ele.save_id
                            // ] = {
                            //   value: 12,
                            // };
                            push_value.status = {
                              value: 12,
                            };
                          }
                        }
                        if (
                          radio_prevalue.checked_value == "Custom Date & Time"
                        ) {
                          if (
                            collection_bottom_sheet_data.data.from !=
                            "switch_on"
                          ) {
                            // push_value.outlet_channel_product_variants_available[
                            //   collection_bottom_sheet_data.data.p_ele.save_id
                            // ] = {
                            //   value: 5,
                            //   time: radio_prevalue.other_value,
                            // };
                            push_value.status = {
                              value: 5,
                              time: radio_prevalue.other_value,
                            };
                          } else {
                            // push_value.outlet_channel_product_variants_available[
                            //   collection_bottom_sheet_data.data.p_ele.save_id
                            // ] = {
                            //   value: 11,
                            //   time: radio_prevalue.other_value,
                            // };
                            push_value.status = {
                              value: 11,
                              time: radio_prevalue.other_value,
                            };
                          }
                        }
                        if (
                          radio_prevalue.checked_value ==
                          "I will turn it on myself"
                        ) {
                          if (
                            collection_bottom_sheet_data.data.from !=
                            "switch_on"
                          ) {
                            // push_value.outlet_channel_product_variants_available[
                            //   collection_bottom_sheet_data.data.p_ele.save_id
                            // ] = {
                            //   value: 2,
                            // };
                            push_value.status = {
                              value: 2,
                            };
                          } else {
                            // push_value.outlet_channel_product_variants_available[
                            //   collection_bottom_sheet_data.data.p_ele.save_id
                            // ] = {
                            //   value: 7,
                            // };
                            push_value.status = {
                              value: 7,
                            };
                          }
                        }
                        console.log("push_value :", push_value);
                        // available_submit(
                        //   push_value,
                        //   collection_bottom_sheet_data.data.p_ele.type,
                        //   collection_bottom_sheet_data.data.p_ele,
                        //   collection_bottom_sheet_data.data.p_index,
                        //   collection_bottom_sheet_data.data.key
                        // );
                        collection_available_submit(
                          push_value,
                          collection_bottom_sheet_data.data.event
                        );
                        return { ...radio_prevalue };
                      });
                    }}
                  >
                    Ok
                  </button>
                </div>
              </div>
            </div>
          </SwipeableBottomSheet>
        </div>
      ) : null}
    </div>
  );
}
